import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { ProfileService } from '../pages/core/profile/services/profile.service'
import { IApiResponse } from '../interfaces/api-response'
import { CookieService } from 'ngx-cookie-service'

@Injectable()
export class CallCenterTotemGuard implements CanActivate {
  constructor (
    private authService: AuthService,
    private profileService: ProfileService,
    private cookieService: CookieService
  ) {
    //
  }

  canActivate (next: ActivatedRouteSnapshot) {
    return new Promise<boolean>(async (resolve) => {
      // store cookie that identifies web totem auth
      if (next.queryParams.redir_from) {
        this.cookieService.set('b1_totem_via_web', next.queryParams.redir_from)
      }

      // check if user is already logged in via login form
      if ((this.authService.isLogged() || this.authService.isTotemLogged()) && !next.queryParams.token) {
        resolve(true)
        return
      }

      // check if user can continue totem login
      const can = this.authService.isLogged() || this.authService.isTotemLogged() || (!!next.queryParams.token)

      if (!can) {
        resolve(false)
        return
      }

      try {
        const { data, token } = await this.login(next.queryParams.token)

        // store organization data
        if (data?.organization) {
          this.authService.setOrganization(data?.organization)
        } else if (next.queryParams.organization) {
          const { organizations } = data.data
          const organization = (organizations || []).find(el => el.id === next.queryParams.organization)

          if (organization) {
            this.authService.setOrganization(organization)
          }
        }

        // store totem auth tokens
        this.storeTotemData(data, token)
      } catch (error) {
        resolve(false)
        return
      }

      resolve(true)
      return
    })
  }

  storeTotemData (data, token) {
    this.authService.setTotemUser(data)
    this.authService.setLang('pt')
    this.authService.setTotemToken(token)
  }

  async login (token: string): Promise<IApiResponse> {
    return new Promise((resolve) => {
      this.profileService
        .totemLogin({ token })
        .subscribe(res => resolve(res), () => resolve(null))
    })
  }
}
