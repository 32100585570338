<ng-template #modalRef>
  <div class="modal--Details">
    <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="formulario">
      <div class="modal-header">
        <h4 class="modal-title">
          {{'call-center::operator::status::change_status' | translate}}
        </h4>
        <button type="button" class="close pull-right" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-form bt-0">
        <div class="form-group">
          <label>{{'call-center::operator::status::select_status' | translate}} <span class="required">*</span></label>
          <select disabled formControlName="status">
            <option value="online">{{'call-center::operator::status::online' | translate}}</option>
            <option value="in_break">{{'call-center::operator::status::in_break' | translate}}</option>
            <option value="administrative" *ngIf="_organization.slug === 'jaguar'">Administrativo</option>
            <option value="offline">Offline</option>
          </select>
        </div>
        <div class="form-group" *ngIf="form.value?.status === 'in_break'">
          <label>Motivo da pausa <span class="required">*</span></label>
          <select formControlName="reason">
            <option [value]="null">Selecione um motivo</option>
            <ng-container *ngFor="let reason of reasons">
              <option
                *ngIf="!(_organization.slug === 'jaguar' && reason?.name === 'Administrativo')"
                [value]="reason?.id"
              >
                {{ reason?.name }}
              </option>
            </ng-container>
          </select>
        </div>
        <div class="form-group" *ngIf="form.value?.status === 'online' && _organization.slug === 'ecp'">
          <label>Guichê</label>
          <input
            type="text"
            formControlName="guiche"
            [attr.disabled]="form.value.isGuicheRequired ? '' : null"
          />
        </div>
        <!-- <div class="form-check">
          <label class="form-check-label pt-0" for="isGuicheRequired">
            <input
              class="form-check-input mt-0"
              type="checkbox"
              [value]="true"
              id="isGuicheRequired"
              formControlName="isGuicheRequired"
            />
            Atendimento online
          </label>
        </div> -->
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="actions d-flex justify-content-end flex-fill">
          <button type="reset" class="btn--White" (click)="closeModal()">
            {{ 'generic::forms::buttons::cancel' | translate }}
          </button>
          <button type="submit" class="ml-2" [disabled]="loading">
            {{ (loading ? 'generic::forms::buttons::loading' : 'generic::forms::buttons::save') | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
