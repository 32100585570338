import { Component, HostBinding, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService as AuthApiService } from "./pages/core/auth/services/auth.service";
import { setTheme } from "ngx-bootstrap/utils";
import * as moment from "moment-timezone";
import { IUser } from "./interfaces/user";
import { I18nService } from "./services/i18n.service";
import { AuthService } from "src/app/services/auth.service";
import { OperatorsService } from "./pages/call-center/services/operators.service";
import { environment } from "src/environments/environment";
import io from "socket.io-client";
import { IOperator } from "./interfaces/operator";
import { OperatorComponent } from "./pages/call-center/operator/operator.component";
import { Router } from "@angular/router";
import { EventBusService } from "ng-simple-event-bus";
import {
  setCall,
  setSip,
  getSip,
} from "./services/currentIncomingCall.service";
import { SettingsService } from "./pages/core/profile/services/settings.service";
import { IntegrationsService } from "./pages/core/profile/services/integrations.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @HostBinding("class") classAttribute;
  year: string;
  user: IUser;
  userLoaded: boolean;
  ecpIsFromGenesys: boolean;
  activePage = window.location.pathname;

  constructor(
    private translate: TranslateService,
    public auth: AuthService,
    private operatorsService: OperatorsService,
    public authService: AuthApiService,
    private i18nService: I18nService,
    private operatorComponent: OperatorComponent,
    private router: Router,
    private event: EventBusService,
    private settingService: SettingsService,
    private integrationsService: IntegrationsService
  ) {
    this.translate.setDefaultLang(this.i18nService.defaultLanguage);
    this.classAttribute = "main--Grid";
    this.year = moment().format("YYYY");
    this.user = this.auth.getUser();

    if (this.auth.isLogged() && this.user) {
      this.setupLanguage();
      this.setupTimezone();
    }

    setTheme("bs4");
  }

  async ngOnInit() {
    if (this.auth.isLogged()) {
      try {
        const me = await this.getMe();
        this.auth.setUser(me);
        this.userLoaded = true;

        const tokenV2 = this.auth.getV2Token();

        if (!tokenV2) {
          const { token } = await this.authService.getToken();
          this.auth.setV2Token(token);
        }
      } catch (error) {
        this.userLoaded = true;
        console.error("Error while fetching user data", error);
      }

      try {
        const organization = this.auth.getOrganization();
        const organizationConfig =
          await this.settingService.getOrganizationConfig(organization.id);
        this.settingService.saveLocallyOrganizationConfig(organizationConfig);
        const operator = await this.getOperator(organization, this.user);

        const socket = io(environment.wsUrl, {
          query: {
            authorization: `Bearer ${this.auth.getToken().token}`,
            room: [`operator::login`],
            operator: operator.id,
            organization: organization.id,
          },
        });
      } catch (error) {
        this.settingService.removeLocallyOrganizationConfig();
        console.error(error.message);
      }

      try {
        const organization = this.auth.getOrganization();
        const organizationIntegrationResponse = await this.integrationsService
          .list(organization.id, { provider: "voice-server" })
          .toPromise();
        if (
          organizationIntegrationResponse.data &&
          organizationIntegrationResponse.data.length
        ) {
          this.settingService.saveLocallyVoiceServerConfig(
            organizationIntegrationResponse.data[0]
          );
        } else {
          this.settingService.removeLocallyVoiceServerConfig();
        }
      } catch (error) {
        this.settingService.removeLocallyVoiceServerConfig();
        console.error(error.message);
      }

      try {
        const audioRingtone = document.getElementById(
          "ringtoneAudioGlobal"
        ) as HTMLAudioElement;
        this.event.on("IncomingCall", (payload) => {
          setCall(payload);

          if (payload && payload?.invitation) {
            audioRingtone.muted = false;
            audioRingtone.play();
          }

          if (!payload) {
            audioRingtone.muted = true;
            audioRingtone.pause();
          }
        });

        this.setDefaultPageForClaroUsers();

        this.router.events.subscribe(
          () => (this.activePage = window.location.pathname)
        );

        if (!getSip())
          this.operatorComponent.handleConnectSip().then(() => setSip(true));
      } catch (error) {
        console.error("Error on Connect to Operator", error);
      }
    }
  }

  getOperator(organization, user): Promise<IOperator> {
    return new Promise((resolve, reject) => {
      this.operatorsService
        .showOperatorByUser(organization.id, user.id)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getMe(): Promise<IUser> {
    return new Promise((resolve, reject) => {
      this.authService.me().subscribe(
        (res) => resolve(res.data),
        (error) => reject(error)
      );
    });
  }

  setupLanguage() {
    const { language } = this.user.settings;
    this.i18nService.setLanguage(language.code);
    this.translate.use(this.i18nService.getLanguage());
  }

  setupTimezone() {
    const lang = this.i18nService.getLanguage();
    moment.locale(lang === "pt" ? "pt-BR" : lang);
    moment.tz.setDefault(this.i18nService.getTimezone());
  }

  isFromGenesys() {
    const ecpIsFromGenesys = localStorage.getItem("@ecp/operator-mode");
    return ecpIsFromGenesys && ecpIsFromGenesys === "limited";
  }

  checkTotemPermission() {
    const role = this.auth.getRole();
    const organization = this.auth.getOrganization();

    if (role === "totem" && organization.slug === "ecp") {
      const currentPathName = window.location.pathname;
      const isAllowed =
        currentPathName.includes("totem") || currentPathName.includes("logout");

      if (!isAllowed) {
        window.location.href = "/call-center/totem";
      }
    }
  }

  setDefaultPageForClaroUsers(): void {
    const role = this.auth.getRole();
    const organization = this.auth.getOrganization();

    if(!window.location.href.includes('suppliers-details') && !window.location.href.includes('/conversations')){

      if (role === "member" && organization.slug === "claro") {
        this.router.navigate(["/call-center/operator"]);
      }

      if (role === "manager" && organization.slug === "claro") {
        this.router.navigate(["/monitoring/suppliers-list"]);
      }

      if (role === "admin" && organization.slug === "claro") {
        this.router.navigate(["/monitoring/suppliers-list"]);
      }
    } 
  }

  checkTvPermission() {
    const role = this.auth.getRole();
    const organization = this.auth.getOrganization();

    if (role === "tv" && organization.slug === "ecp") {
      const currentPathName = window.location.pathname;
      const isAllowed =
        currentPathName.includes("tv-queue") ||
        currentPathName.includes("logout");

      if (!isAllowed) {
        window.location.href = "/call-center/tv-queue";
      }
    }
  }

  canShowPageStructure() {
    const currentPathName = window.location.pathname;

    const deniedPaths = [
      "/call-center/totem",
      "/call-center/totem/schedule",
      "/call-center/tv-queue",
      "/call-center/totem/edit-conversation",
      "/call-center/totem/sauna",
    ];

    return !deniedPaths.includes(currentPathName);
  }
}
