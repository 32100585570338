import { Injectable } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { HttpClient } from '@angular/common/http'
import { BaseService } from 'src/app/services/base.service'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'
import { IConversationQueue } from 'src/app/interfaces/conversation-queue'

@Injectable({
  providedIn: 'root'
})
export class TicketsService extends BaseService {
  constructor(auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  list(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/tickets/`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  store(organizationId: string, data): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/tickets/`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  show(organizationId: string, ticket: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/tickets/${ticket}`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  update(organizationId: string, ticket: string, data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/tickets/${ticket}`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  delete(organizationId: string, ticket: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${environment.apiUrl}/tickets/${ticket}`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  makePrintableRule(
    queue: IConversationQueue, relationship?: any
  ): Array<{ label: string; canSeeElement: boolean; appearsOnPrint: boolean }> {
    return [
      {
        label: 'title',
        canSeeElement: queue?.data?.title,
        appearsOnPrint: queue?.data?.title_imprimir
      },
      {
        label: 'ocurrencyType',
        canSeeElement: queue?.data?.ocurrencyType,
        appearsOnPrint: queue?.data?.ocurrencyType_imprimir
      },
      {
        label: 'area',
        canSeeElement: queue?.data?.area,
        appearsOnPrint: queue?.data?.area_imprimir
      },
      {
        label: 'local',
        canSeeElement: queue?.data?.local,
        appearsOnPrint: queue?.data?.local_imprimir
      },
      {
        label: 'localType',
        canSeeElement: queue?.data?.localType,
        appearsOnPrint: queue?.data?.localType_imprimir
      },
      {
        label: 'ocurrencyDate',
        canSeeElement: queue?.data?.occurencyDate,
        appearsOnPrint: queue?.data?.occurencyDate_imprimir
      },
      {
        label: 'comunicationDate',
        canSeeElement: queue?.data?.comunicationDate,
        appearsOnPrint: queue?.data?.comunicationDate_imprimir
      },
      {
        label: 'recebimento',
        canSeeElement: queue?.data?.recebimento,
        appearsOnPrint: queue?.data?.recebimento_imprimir
      },
      {
        label: 'reason',
        canSeeElement: queue?.data?.reason,
        appearsOnPrint: queue?.data?.reason_imprimir
      },
      {
        label: 'subReason',
        canSeeElement: queue?.data?.subreason,
        appearsOnPrint: queue?.data?.subreason_imprimir
      },
      {
        label: 'classification',
        canSeeElement: queue?.data?.classification,
        appearsOnPrint: queue?.data?.classification_imprimir
      },
      {
        label: 'image',
        canSeeElement: queue?.data?.image,
        appearsOnPrint: queue?.data?.image_imprimir
      },
      {
        label: 'note',
        canSeeElement: queue?.data?.note,
        appearsOnPrint: queue?.data?.note_imprimir
      },
      {
        label: 'aditional',
        canSeeElement: queue?.data?.aditionalNote,
        appearsOnPrint: queue?.data?.aditionalNote_imprimir
      },
      {
        label: 'objects',
        canSeeElement: queue?.data?.objects,
        appearsOnPrint: queue?.data?.objects_imprimir
      },
      {
        label: 'relationshipsField',
        canSeeElement: queue?.data?.relationshipsField,
        appearsOnPrint: queue?.data?.relationshipsField_imprimir
      },
    ]
  }
}
