import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/services/base.service'
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/services/auth.service'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'

export interface SearchOnEcpDatabaseParamsModel {
  entity: string
  nome?: string
  matricula?: string
  rg?: string
}

export interface SearchOnEcpDatabaseResponse {
  associado?: any[]
  associados?: any[]
  acompanhante?: any[]
  convidado?: any[]
  visitante?: any[]
  torcida?: any[]
  prestador?: any[]
  funcionario?: any[]
}

@Injectable({
  providedIn: 'root'
})
export class ECPCallCenterService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  search (organizationId: string, params = {}, customToken = null): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/ecp/contacts`, {
      params,
      headers: {
        ...this.buildAuthToken(customToken),
        'B1-Organization': organizationId
      }
    })
  }

  update (organizationId: string, id: string, data: any, originRequested?: string): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/ecp/contacts/${id}`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      },
      params: {
        origin: originRequested
      }
    })
  }

  queue (organizationId: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/ecp/queue`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  loginAsInPortal (contactId: string, url = null): Promise<any> {
    return this.http
      .post(`${url || environment.ecpPortalUrl}/login-as/redirect`, null, {
        params: { contact: contactId }
      })
      .toPromise()
  }

  searchOnEcpDatabase (organizationId: string, params: SearchOnEcpDatabaseParamsModel): Promise<SearchOnEcpDatabaseResponse> {
    return this.http.get<SearchOnEcpDatabaseResponse>(`${environment.apiUrl}/ecp/workflow`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      },
      params: {
        ...params
      }
    }).toPromise()
  }

  importOnEcpDatabase (organizationId: string, referenceId: string): Promise<SearchOnEcpDatabaseResponse> {
    return this.http.post<SearchOnEcpDatabaseResponse>(`${environment.apiUrl}/ecp/workflow`,{ referenceId }, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    }).toPromise()
  }

  updateContactRecidivist (organizationId: string, id: string, data: any): Promise<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/ecp/workflow/contact/${id}`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    }).toPromise()
  }
}
