import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  Injector,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap'
import { FormGroup, Validators } from '@angular/forms'
import { OperatorsService } from 'src/app/pages/call-center/services/operators.service'
import { BasePage } from '../base-page/base-page'
import { IOperatorBreakReason } from 'src/app/interfaces/operator-break-reason'
import { IOperator } from 'src/app/interfaces/operator'
import { IOperatorBreak } from 'src/app/interfaces/operator-break'
import { STATUS_TYPE } from 'src/app/interfaces/operator-status-log-v2'
import { EventBusService } from 'ng-simple-event-bus'

import * as moment from 'moment-timezone'

@Component({
  selector: 'app-operator-status-modal',
  templateUrl: './operator-status-modal.component.html',
  styleUrls: ['./operator-status-modal.component.scss']
})
export class OperatorStatusModalComponent extends BasePage implements OnInit, OnChanges {
  @ViewChild('modalRef') modalRef: ElementRef
  @Input() show: boolean
  @Output() showChange: EventEmitter<boolean>
  @Output() updated: any

  form: FormGroup
  modal: BsModalRef
  formErrors: any
  loading: boolean
  operator: IOperator
  reasons: Array<IOperatorBreakReason>

  constructor (injector: Injector, private modalService: BsModalService, private service: OperatorsService, private event: EventBusService) {
    super(injector)
    this.showChange = new EventEmitter()
    this.updated = new EventEmitter()

    this.form = this._formBuilder.group({
      status: [null, Validators.required],
      guiche: [null],
      isGuicheRequired: [false],
      reason: [null]
    })
  }

  ngOnInit () {
    //
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.show && changes.show.currentValue) {
      this.showModal()
      this.loadData()
    }
  }

  resetForm (): void {
    this.form.reset()
    this.form.patchValue({ isGuicheRequired: false })
  }

  showModal (): void {
    this.modal = this.modalService.show(this.modalRef, { ignoreBackdropClick: true, animated: false })
    this.resetForm()
    this.formErrors = {}

    this.modalService.onHide.subscribe(() => {
      this.show = false
      this.showChange.emit(this.show)
    })
  }

  closeModal (): void {
    this.modal.hide()
    this.resetForm()
    this.show = false
    this.showChange.emit(this.show)
    this.loading = false
  }

  async loadData () {
    this.form.controls['status'].disable()
    if (!this.reasons || this.reasons.length === 0) {
      this.reasons = await this.getBreakReasons()

      if (this._organization.slug === 'jaguar') {
        const selectedPauses = ['Treinamento', 'Pessoal', 'Descanso', 'Almoço', 'Administrativo', 'Feedback']

        this.reasons = this.reasons.filter((el) => selectedPauses.includes(el.name))
      }
    }

    this.operator = await this.getOperator()
    const status = this.operator?.custom_data?.administrative ? 'administrative' : this.operator?.status

    this.form.controls.status.setValue(status)
    this.form.controls.guiche.setValue(this.operator.guiche)

    if (this.operator.status) {
      const lastBreak = (this.operator.breaks || [])
        .sort((a, b) => {
          const dateA = moment(a.started_at)
          const dateB = moment(b.started_at)

          if (dateA.isBefore(dateB)) {
            return 1
          } else if (dateA.isAfter(dateB)) {
            return -1
          }

          return 0
        })
        .find((el) => !el?.finished_at)

      if (lastBreak) {
        this.form.controls.reason.setValue(lastBreak.reason_id)
      }
    }

    this.form.controls['status'].enable()
  }

  getOperator (): Promise<IOperator> {
    return new Promise((resolve, reject) => {
      this.service.showOperatorByUser(this._organization.id, this._auth.getUser().id).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
    })
  }

  getBreakReasons (): Promise<Array<IOperatorBreakReason>> {
    return new Promise((resolve, reject) => {
      this.service.listBreakReasons(this._organization.id).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
    })
  }

  saveBreak (data): Promise<IOperatorBreak> {
    return new Promise((resolve, reject) => {
      this.service.storeBreak(this._organization.id, this.operator.id, data).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
    })
  }

  updateOperator (data): Promise<IOperatorBreak> {
    return new Promise((resolve, reject) => {
      this.service.update(this._organization.id, this.operator.id, data).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
    })
  }

  async handleSubmit () {
    if (this.form.valid) {
      const { reason, guiche } = this.form.value
      let { status } = this.form.value

      if (
        (status == 'offline' && this?.operator?.status == 'in_break') ||
        (status == 'in_break' && this?.operator?.status == 'offline')
      ) {
        this._toast.error('O Operador precisa estar online')
        return
      }

      const custom_data = { administrative: false }

      if (status === 'in_break' && (!reason || String(reason).length === 0)) {
        this._toast.error('Selecione um motivo para a pausa.')
        return
      }

      this.loading = true
      if (status === 'in_break') {
        await this.saveBreak({ reason_id: reason })
      }

      if (status === 'administrative') {
        status = 'online'
        const administrativeReason = this.reasons.find((el) => el.name === 'Administrativo')
        if (administrativeReason) {
          await this.saveBreak({ reason_id: administrativeReason.id })
          custom_data.administrative = true
        }
      }

      const reasonBreak: IOperatorBreakReason = this.reasons.find((el) => el.id == reason)
      const newStatus: any = await this.updateOperator({ status, guiche, custom_data })
      await this.service
        .updateStatus(this?._organization?.id, this?.operator?.id, {
          status: STATUS_TYPE[status],
          reason,
          break_pause_name: reasonBreak?.name,
          organization_id: this?._organization?.id,
          operator_name: `${this?.operator?.user?.first_name} ${this?.operator?.user?.last_name}`
        })
        .then(() => {})
        .catch((err) => console.error(err))

      const operatorBreakStatus = await this.service
        .getLastInBreakStatus(this?._organization?.id, this?.operator?.id, 'supervisor')
        .toPromise()

      this.updated.emit({ operator: newStatus, toUpdateCounter: operatorBreakStatus.data })
      this.closeModal()
      this.loading = false

      // isso é para atualizar o status (assim não precisamos ficar toda hora batendo no servidor para verificar status)
      this.event.trigger('operatorStatus', newStatus)

      this._toast.success('Status atualizado com sucesso!')
    }
  }
}
