import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor (private service: TranslateService) {}

  get (key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.service
        .get(key)
        .subscribe(res => resolve(res), () => reject(null))
    })
  }
}
