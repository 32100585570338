<div class="main--Nav fade-in-left alt--Nav">
  <div class="logo--Menu">
    <img src="./assets/img/brand/2x/logo-b1-white@2x.png" />
  </div>
  <ul>
    <app-nav-item route="/dashboard" label="menu::dashboard" icon="ico--Dashboard"
      *ngIf="allowedModules.includes(modules.DASHBOARD)"></app-nav-item>
    <app-nav-item route="/contacts" label="menu::contacts" icon="ico--Contato"
      *ngIf="allowedModules.includes(modules.CONTACTS)"></app-nav-item>
    <app-nav-item route="/accounts" label="menu::accounts" icon="ico--Contas"
      *ngIf="allowedModules.includes(modules.ACCOUNTS) && !ecpOperator"></app-nav-item>
    <app-nav-item route="/products" label="menu::products" icon="ico--Push"
      *ngIf="allowedModules.includes(modules.PRODUCTS)"></app-nav-item>
    <app-nav-item route="/calendar" label="menu::calendar" icon="ico--Calendario"
      *ngIf="allowedModules.includes(modules.CALENDAR)"></app-nav-item>
    <app-nav-item route="/leads" label="menu::leads" icon="ico--Lead"
      *ngIf="allowedModules.includes(modules.LEADS)"></app-nav-item>
    <app-nav-item route="/opportunities" label="menu::opportunities" icon="ico--Vendas"
      *ngIf="allowedModules.includes(modules.OPPORTUNITIES)"></app-nav-item>
    <app-nav-item route="/call-center/operator" label="menu::call-center::operator" icon="ico--Operador"
      *ngIf="allowedModules.includes(modules.CONTACT_CENTER_OPERATOR)"></app-nav-item>
    <app-nav-item route="/call-center/admin" label="menu::call-center::admin" icon="ico--Supervisor"
      *ngIf="allowedModules.includes(modules.CONTACT_CENTER_ADMIN) && !ecpOperator"></app-nav-item>
    <app-nav-item route="/call-center/conversations" label="menu::conversations" icon="ico--Tickets"
      *ngIf="allowedModules.includes(modules.CONTACT_CENTER_CONVERSATIONS) || ecpOperator"></app-nav-item>
    <app-nav-item route="/service-scheduler" label="menu::service-scheduler" icon="ico--Calendario"
      *ngIf="allowedModules.includes(modules.SERVICE_SCHEDULER) && !ecpOperator"></app-nav-item>
    <app-nav-item route="/photos" label="menu::photos" icon="ico--Push"
      *ngIf="allowedModules.includes(modules.PHOTOS)"></app-nav-item>
    <app-nav-item route="/knowledge-base" label="menu::knowledge" icon="ico--Projetos"
      *ngIf="allowedModules.includes(modules.KNOWLEDGE_BASE)"></app-nav-item>
    <app-nav-item route="/cost-center" label="menu::cost-center" icon="ico--Projetos"
      *ngIf="allowedModules.includes(modules.COST_CENTER)"></app-nav-item>
    <app-nav-item route="/recordings" label="menu::recordings" icon="ico--Recording"
      *ngIf="allowedModules.includes(modules.RECORDINGS)"></app-nav-item>
    <app-nav-item route="/monitoring" label="menu::monitoring" icon="ico--Monitoring"
      *ngIf="allowedModules.includes(modules.MONITORINGS)"></app-nav-item>
    <app-nav-item class="logout" route="/logout" label="menu::logout" icon="ico--Sair"
      customClass="logout--Menuitem"></app-nav-item>
  </ul>
</div>