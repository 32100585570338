import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { RouterModule } from '@angular/router'
import { OperatorStatusModalComponent } from './operator-status-modal.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule, FormsModule, ReactiveFormsModule],
  declarations: [OperatorStatusModalComponent],
  exports: [OperatorStatusModalComponent]
})
export class OperatorStatusModalModule {}
