import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler, LOCALE_ID, APP_INITIALIZER, enableProdMode } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserGuard } from './services/user.guard'
import { GuestGuard } from './services/guest.guard'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { HeaderModule } from './components/header/header.module'
import { NavModule } from './components/nav/nav.module'
import { LogoutComponent } from './pages/core/logout/logout.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { NgxSelectModule } from 'ngx-select-ex'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { DropdownModule } from 'ngx-dropdown'
import { TooltipModule } from 'ngx-tooltip'
import { NgxCurrencyModule } from 'ngx-currency'
import { ptBrLocale } from 'ngx-bootstrap/locale'
import { BsLocaleService } from 'ngx-bootstrap'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { registerLocaleData } from '@angular/common'
import localePtBr from '@angular/common/locales/pt'
import { I18nService } from './services/i18n.service'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { AvatarModule } from 'ngx-avatar'
import { NgxMaskModule } from 'ngx-mask'
import { CallCenterGuard } from './services/call-center.guard'
import {
  OwlNativeDateTimeModule,
  OwlMomentDateTimeModule,
  OwlDateTimeModule
} from '@danielmoncada/angular-datetime-picker'
import { CallCenterTotemGuard } from './services/totem.guard'
import { CookieService } from 'ngx-cookie-service'
import { ConversationService } from './services/conversation.service'
import { ConversationOperatorsService } from './services/conversation-operators.service'
import { QueuesService } from './services/queues-service'
// import * as Sentry from '@sentry/angular'
import { init, routingInstrumentation, TraceService, createErrorHandler } from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'
import { environment } from '../environments/environment'
import { OperatorComponent } from './pages/call-center/operator/operator.component'
import { SharedModule } from './shared/shared.module'
import { ReceptiveCallComponentAlert } from './shared/components/receptive-call-alert/receptive-call-alert.component'
import { EventBusService } from 'ng-simple-event-bus'
import { Router } from "@angular/router";

init({
  dsn: 'https://f38d0de907864cb4874bf0dd3b59755a@o1296094.ingest.sentry.io/6523114',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [environment.apiUrl],
      routingInstrumentation: routingInstrumentation
    })
  ],
  tracesSampleRate: 1.0,
  environment: environment.production ? 'production' : 'development'
})

enableProdMode();

defineLocale('pt', ptBrLocale)
registerLocaleData(localePtBr, 'pt')

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@NgModule({
  declarations: [AppComponent, LogoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    HeaderModule,
    NavModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 3,
      autoDismiss: true
    }),
    ModalModule.forRoot(),
    TooltipModule,
    NgxSelectModule,
    NgbModule,
    DropdownModule,
    NgxCurrencyModule,
    NgxMaterialTimepickerModule,
    AvatarModule,
    NgxMaskModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    SharedModule
  ],
  providers: [
    EventBusService,
    ReceptiveCallComponentAlert,
    OperatorComponent,
    UserGuard,
    GuestGuard,
    CallCenterGuard,
    BsModalRef,
    QueuesService,
    BsLocaleService,
    ConversationOperatorsService,
    ConversationService,
    CallCenterTotemGuard,
    CookieService,
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (service) => service.getLanguage()
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    // {
    //   provide: TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [TraceService],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
