import { CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { ProfileService } from '../pages/core/profile/services/profile.service'
import { IApiResponse } from '../interfaces/api-response'

@Injectable()
export class CallCenterGuard implements CanActivate {
  constructor (
    private authService: AuthService,
    private profileService: ProfileService
  ) {
    //
  }

  canActivate (next: ActivatedRouteSnapshot) {
    return new Promise<boolean>(async (resolve) => {
      if (this.authService.isLogged() && !next.queryParams.token) {
        resolve(true)
        return
      }

      const can = this.authService.isLogged() || !!next.queryParams.token

      if (!can) {
        resolve(false)
        return
      }

      try {
        const data = await this.login(next.queryParams.token)

        this.authService.setUser(data.data)
        this.authService.setToken(data.token)
        this.authService.setLang('pt')
        this.authService.setVar('@ecp/operator-mode', 'limited')

        if (next.queryParams.conversation) {
          this.authService.setVar('@ecp/conversation', next.queryParams.conversation)
        }

        if (next.queryParams.organization) {
          const { organizations } = data.data
          const organization = (organizations || []).find(el => el.id === next.queryParams.organization)

          if (organization) {
            this.authService.setOrganization(organization)
          }
        }
      } catch (error) {
        resolve(false)
        return
      }

      resolve(true)
      return
    })
  }

  async login (token: string): Promise<IApiResponse> {
    return new Promise((resolve) => {
      this.profileService
        .me(token)
        .subscribe(res => resolve(res), () => resolve(null))
    })
  }
}
