import { IOrganization } from "src/app/interfaces/organization";
import { IntegrationsService } from "../pages/core/profile/services/integrations.service";
import { HttpClient } from "@angular/common/http";
import { OrganizationIntegration } from "../interfaces/organization-integration";

export interface VoiceDriver {
  inbound: boolean;
  outbound: boolean;
  sipDomain: string;
  sipServer: string;
  driver: "freepbx" | "freeswitch";
  queues: Queue;
}

interface VoiceDriverModel {
  [organization: string]: VoiceDriver;
}

interface Queue {
  [queue: string]: string | null;
}

export class VoiceHelper {
  private voiceDriver: VoiceDriver;

  static create(
    organization: IOrganization,
    organizationIntegrationVoiceServer?: OrganizationIntegration
  ) {
    return new VoiceHelper(organization, organizationIntegrationVoiceServer);
  }

  constructor(
    private readonly organization: IOrganization,
    organizationIntegrationVoiceServer?: OrganizationIntegration
  ) {
    this.voiceDriver = this.voiceDrivers[this.organization?.slug];

    if (organizationIntegrationVoiceServer) {
      this.voiceDriver =
        organizationIntegrationVoiceServer.credentials as unknown as VoiceDriver;
    }
  }

  private voiceDrivers: VoiceDriverModel = {
    medlevensohn: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-medlevensohn.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: null,
        100: "619117c1-08df-4b30-88fe-0ed0862df23c",
        101: "f4ff86f8-f72b-46ac-8c25-06841832f53b",
        608: "9f580861-efad-42f6-ba66-45d9d5e5dc0a",
        609: "d5828884-fb30-49ee-818d-62ec4b50cf37",
      },
    },
    jaguar: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-jaguar.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "d6915c39-c61a-417e-96c0-82128f1d955d",
        610: "af54b67c-7ca0-425d-8f73-68e3251d9c3f",
        611: "35cedafe-9511-45c6-b0cb-d8b8720586da",
      },
    },
    ecp: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-ecp.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "a71a510a-af21-468e-8c90-a5255b98b0ee",
        500: "5aa4f5c8-546b-48f3-b771-2272321fe602",
        600: "6f17d29c-57b6-423d-bca0-41881d1b28ce",
      },
    },
    blueone: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "srv01core.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: null,
        3000: "b1793891-8288-42bc-87c5-05b647b57fdb",
        3001: "b8a98e4e-59df-4248-80e5-3b97609dc1c1",
        3002: "55b4ff03-043e-4074-a73a-2b074009e086",
      },
    },
    pedepronto: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "pedepronto.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "87a4ab99-7f35-471d-8aae-e694e30edb10",
        604: "5a54226d-502b-42e1-9b72-017bf756bb43",
        605: "05b3ca4e-9d88-40d0-900e-f9c44d70ef3f",
      },
    },
    "serasa-reversao": {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-serasa.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "2282a5d9-7916-4b52-8db7-f1d4ca880118",
      },
    },
    pva: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-pva.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "7765e68e-722e-473e-92d3-956d1a6e8572",
      },
    },
    vr: {
      inbound: true,
      outbound: true,
      driver: "freeswitch",
      sipDomain: "voice-vr.b1app.com.br",
      sipServer: "wss://pedepronto.b1app.com.br:7443",
      queues: {
        outbound: "225a7ce9-eb7b-4036-960a-f41ca69c5201",
      },
    },
  };

  canUseVoiceModule(module: "outbound" | "inbound") {
    return this.voiceDriver ? this.voiceDriver?.[module] : null;
  }

  fetchVoiceDriver(): "freepbx" | "freeswitch" {
    return this.voiceDriver ? this.voiceDriver?.driver : null;
  }

  fetchSipDomain(): string {
    return this.voiceDriver ? this.voiceDriver?.sipDomain : null;
  }

  fetchSipServer(): string {
    return this.voiceDriver ? this.voiceDriver?.sipServer : null;
  }

  fetchQueueIdByName(name: string) {
    return this.voiceDriver ? this.voiceDriver?.queues[name] || null : null;
  }

  fetchDriverConfig(): VoiceDriver | null {
    return this.voiceDriver ? this.voiceDriver : null;
  }
}
