import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

interface token {
  data: {
    token: string;
  }
}

export interface INotifications {
  id: string
  organizationId: string
  userId: string
  entityType: string
  entityId: string
  title: string
  message: string
  actionLabel: string
  actionUrl: string
  createdAt: string
  updatedAt: string
  isReaded: boolean
}

export interface PaginatedResponse {
  page: number
  totalRows: number
  perPage: number
  data: INotifications[]
}

@Injectable({
  providedIn: 'root'
})
export class notificationService {
  url: string

  constructor (private http: HttpClient) {
    this.url = environment?.notificationUrl
  }

  async getToken (userId: string, organizationId: string): Promise<token> {
    return this.http
      .post<any>(`${this.url}/auth`, null, {
        headers: {
          Authorization: `Basic ${btoa(`${userId}:${organizationId}`)}`
        }
      })
      .toPromise()
  }

  async getNotifications (userId: string, organizationId: string, page: number): Promise<PaginatedResponse> {
    const { data } = await this.getToken(userId, organizationId)
    return this.http
      .get<PaginatedResponse>(`${this.url}/notifications`, {
        headers: {
          Authorization: data.token
        },
        params: { page: page.toString() }
      })
      .toPromise()
  }

  async markReadForNotifications (userId: string, organizationId: string, notificationId: string) {
    const { data } = await this.getToken(userId, organizationId)
    return this.http
      .post(`${this.url}/notifications/${notificationId}/read`, null, {
        headers: {
          Authorization: `Bearer ${data.token}`
        }
      })
      .toPromise()
  }
}
