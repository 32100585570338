import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/services/base.service'
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/services/auth.service'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'

export class ConversationOperatorsService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
      super(auth)
    }

  getAll (organizationId: string, params = {}): Promise<IApiResponse> {
       return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/operators-v2?order_column=users.first_name&order_type=asc`, {
          params,
          headers: {
            ...this.buildAuthToken(), 'B1-Organization': organizationId
          }
        }).toPromise()
     }
}
