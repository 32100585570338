import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { RouterModule } from '@angular/router'
import { OrganizationSwitcherComponent } from './organization-switcher.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule, BsDropdownModule.forRoot()],
  declarations: [OrganizationSwitcherComponent],
  exports: [OrganizationSwitcherComponent]
})
export class OrganizationSwitcherModule {}
