import { Injectable } from '@angular/core'
import { IUser } from '../interfaces/user'
import { IToken } from '../interfaces/token'
import { IOrganization } from '../interfaces/organization'
import { IConversationForm } from '../interfaces/conversation-form-storage'
import { filter } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userKey: string
  tokenKey: string
  totemUserKey: string
  totemTokenKey: string
  tokenV2Key: string
  organizationKey: string
  filterStorageKey: string
  constructor () {
    this.userKey = '@b1/user'
    this.tokenKey = '@b1/token'
    this.tokenV2Key = '@b1/token-v2'
    this.totemUserKey = '@b1/TOTEM_USER_INFO'
    this.totemTokenKey = '@b1/TOTEM_TOKEN_CREDENTIALS'
    this.organizationKey = '@b1/organization'
    this.filterStorageKey = '@b1/conversation-filter'
  }

  setVar (key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  setLang (lang: string): void {
    localStorage.setItem('@b1/lang', lang)
  }

  setUser (user: IUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(user))
  }

  setTotemUser (user: IUser): void {
    localStorage.setItem(this.totemUserKey, JSON.stringify(user))
  }

  getUser (): IUser {
    const data = localStorage.getItem(this.userKey)
    return data ? JSON.parse(data) : null
  }

  getTotemUser (): IUser {
    const data = localStorage.getItem(this.totemUserKey)
    return data ? JSON.parse(data) : null
  }

  setToken (token: IToken): void {
    localStorage.setItem(this.tokenKey, JSON.stringify(token))
  }

  setTotemToken (token: IToken): void {
    localStorage.setItem(this.totemTokenKey, JSON.stringify(token))
  }

  getToken (): IToken {
    const data = localStorage.getItem(this.tokenKey)
    return data ? JSON.parse(data) : null
  }

  getV2Token (): string {
    const data = localStorage.getItem(this.tokenV2Key)
    return data || null
  }

  setV2Token (token: string): void {
    localStorage.setItem(this.tokenV2Key, token)
  }

  getTotemToken (): IToken {
    const data = localStorage.getItem(this.totemTokenKey)
    return data ? JSON.parse(data) : null
  }

  setOrganization (organization: IOrganization): void {
    localStorage.setItem(this.organizationKey, JSON.stringify(organization))
  }

  getFilterStorage (key = this.filterStorageKey) {
    const filterStorage = localStorage.getItem(key)
    return filterStorage ? JSON.parse(filterStorage) : null
  }

  getOrganizations (): Array<IOrganization> {
    const data = this.getUser()
    return data ? data.organizations : []
  }

  getOrganization (): IOrganization {
    const data = localStorage.getItem(this.organizationKey)
    const user = this.getUser()

    if (!data && user) {
      const { organizations } = this.getUser()
      return organizations.length > 0 ? organizations[0] : null
    }

    return data ? JSON.parse(data) : null
  }

  getTotemOrganization (): IOrganization {
    const data = localStorage.getItem(this.organizationKey)

    if (!data) {
      const { organizations } = this.getTotemUser()
      return organizations.length > 0 ? organizations[0] : null
    }

    return data ? JSON.parse(data) : null
  }

  logout (): void {
    localStorage.clear()
  }

  isLogged (): boolean {
    const user = this.getUser()
    return user ? true : false
  }

  isTotemLogged (): boolean {
    const user = this.getTotemUser()
    const token = this.getTotemToken()
    return user && token ? true : false
  }

  getLang (): string {
    const user = this.getUser()

    if (this.isLogged() && user) {
      const language = user.settings.language.code || 'en'
      return language
    }

    return 'pt'
  }

  getTimezone (): string {
    const user = this.getUser()

    if (this.isLogged() && user) {
      const timezone = user.settings.timezone || 'America/Sao_Paulo'
      return timezone
    }

    return 'America/Sao_Paulo'
  }

  getDisplayName (): string {
    if (this.isLogged()) {
      const { first_name, last_name } = this.getUser()
      return String(`${first_name} ${last_name}`).trim()
    }

    return null
  }

  getRole (): string {
    const { roles } = this.getUser()
    const roleSlug = roles.length > 0 ? roles[0]?.slug : null

    return roleSlug
  }

  can (module: string) {
    const rules = {
      owner: {},
      admin: {},
      manager: {
        'core::profile::organization': false,
        'core::profile::organization_members': false,
        'interactions::delete': false
      },
      member: {
        'core::profile::organization': false,
        'core::profile::organization_members': false,
        'core::profile::integrations': false,
        'core::profile::email_config': false,
        'interactions::delete': false
      }
    }

    const { roles } = this.getUser()
    const roleSlug = roles.length > 0 ? roles[0]?.slug : null

    if (!roleSlug) {
      return false
    }

    if (typeof rules[roleSlug][module] !== 'undefined') {
      return rules[roleSlug][module]
    }

    return true
  }
}
