import { NgModule } from '@angular/core'
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { UserGuard } from './services/user.guard'
import { GuestGuard } from './services/guest.guard'
import { LogoutComponent } from './pages/core/logout/logout.component'
import { CallCenterGuard } from './services/call-center.guard'
import { CallCenterTotemGuard } from './services/totem.guard'

const defaultPath = '/call-center/operator'

const routes: Routes = [
  {
    path: '',
    redirectTo: defaultPath,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/core/auth/auth.module').then(m => m.AuthModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'docs',
    loadChildren: () => import('./pages/core/docs/docs.module').then(m => m.DocsModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/core/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [UserGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [UserGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/core/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [UserGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/list/list.module').then(m => m.ContactsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'accounts',
    loadChildren: () => import('./pages/accounts/list/list.module').then(m => m.AccountsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'leads',
    loadChildren: () => import('./pages/leads/leads.module').then(m => m.LeadsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/tasks/list/list.module').then(m => m.TasksModule),
    canActivate: [UserGuard]
  },
  {
    path: 'opportunities',
    loadChildren: () => import('./pages/opportunities/opportunities.module').then(m => m.OpportunitiesModule),
    canActivate: [UserGuard]
  },
  {
    path: 'call-center',
    loadChildren: () => import('./pages/call-center/call-center.module').then(m => m.CallCenterModule),
    canActivate: [UserGuard, CallCenterGuard]
  },
  {
    path: 'call-center/totem',
    loadChildren: () => import('./pages/call-center/totem/ticket.module').then(m => m.TicketModule),
    canActivate: [CallCenterTotemGuard]
  },
  {
    path: 'call-center/totem/edit-conversation',
    loadChildren: () => import('./pages/call-center/edit-schedule/edit-schedule.module').then(m => m.EditScheduleModule),
    canActivate: [CallCenterTotemGuard]
  },
  {
    path: 'photos',
    loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule),
    canActivate: [UserGuard]
  },
  {
    path: 'procedures',
    loadChildren: () => import('./pages/procedures/procedures.module').then(m => m.ProceduresModule),
    canActivate: [UserGuard]
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'service-scheduler',
    loadChildren: () => import('./pages/service-scheduler/service-scheduler.module').then(m => m.ServiceSchedulerModule),
    canActivate: [UserGuard]
  },
  {
    path: 'knowledge-base',
    loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
    canActivate: [UserGuard]
  },
  {
    path: 'cost-center',
    loadChildren: () => import('./pages/cost-center/cost-center.module').then(m => m.CostCenterModule),
    canActivate: [UserGuard]
  },
  {
    path: 'recordings',
    loadChildren: () => import('./pages/recordings/recordings.module').then(m => m.RecordingsModule),
    canActivate: [UserGuard]
  },
  {
    path: 'monitoring',
    loadChildren: () => import('./pages/monitoring/monitoring.module').then(m => m.MonitoringModule),
    canActivate: [UserGuard]
  },
  {
    path: '**',
    redirectTo: defaultPath
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'
    }
  )
  ],
  // providers: [ { provide: LocationStrategy, useClass: HashLocationStrategy } ],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
