import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { Observable } from "rxjs";
import { IApiResponse } from "src/app/interfaces/api-response";
import { environment } from "src/environments/environment";
import { IConversationMessage } from "src/app/interfaces/conversation-message";

export interface CustomFields {
  [key: string]: any;
}

export interface ICall {
  id: string;
  conversation_id: string;
  direction: string;
  phone: string;
  extension?: any;
  duration: number;
  started_at: Date;
  finished_at: Date;
  reference: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
  connected_at?: any;
  custom_fields: CustomFields;
  error_code?: any;
  error_details?: any;
  is_recorded: boolean;
  recording_url?: any;
  tabulation_status?: any;
  tabulation_at?: any;
  recording_source?: any;
  [key: string]: any;
}

@Injectable({
  providedIn: "root",
})
export class ConversationsService extends BaseService {
  // This property is so that at any place of the application one can redirect to the conversation chat tab with any attachment they wish
  private attachments: any[];

  constructor(auth: AuthService, private http: HttpClient) {
    super(auth);
  }

  getAttachments(): any[] {
    return this.attachments;
  }

  setAttachments(attachments: any[]): void {
    this.attachments = attachments;
  }

  pushAttachment(attachment: any): number {
    if (!this.attachments) {
      this.setAttachments([]);
    }

    return this.attachments.push(attachment);
  }

  getNextDistribution(
    organizationId: string,
    params = {}
  ): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/call-center/conversations/next`,
        {},
        {
          params,
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  list(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  findContactByCustomField(
    organizationId: string,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/contacts/conversations/by-custom-fields`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  filter(
    organizationId: string,
    params = {},
    data = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/by-filters`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  store(
    organizationId: string,
    data: any,
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations`,
      data,
      {
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  update(
    organizationId: string,
    id: string,
    data: any,
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}`,
      data,
      {
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  delete(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  transfer(
    organizationId: string,
    id: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/transfer`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  associate(
    organizationId: string,
    id: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/associate`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  show(
    organizationId: string,
    id: string,
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}`,
      {
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  showIdLike(
    organizationId: string,
    id: string,
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/like`,
      {
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  updateStatus(
    organizationId: string,
    id: string,
    data: any,
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/status`,
      data,
      {
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  // | ------------------------------|
  // | Conversation messages         |
  // | ------------------------------|
  listMessages(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/messages`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  storeMessage(
    organizationId: string,
    id: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/messages`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  callback(
    organizationId: string,
    conversationId: string,
    operatorId: string
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operatorId}/webhook`,
      { conversationId },
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  getMessageHistory(
    organizationId: string,
    contactId: string,
    conversationId?: string,
    type?: string
  ): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(
        `${environment.apiUrl}/call-center/messages/history/${contactId}?conversationId=${conversationId}&type=${type}`,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  // | ------------------------------|
  // | Conversation calls            |
  // | ------------------------------|
  getTwilioVoiceToken(organizationId: string): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/integrations/twilio/voice-token`,
        {},
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  sendSMS(
    organizationId: string,
    content: string,
    toNumber?: string
  ): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/integrations/sms`,
        {
          toNumber,
          content,
        },
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  getTwilioNTSToken(organizationId: string): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/integrations/twilio/nts-token`,
        {},
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  storeCall(
    organizationId: string,
    id: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/call-center/conversations/${id}/calls`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  getCalls(
    organizationId: string,
    params = {},
    data = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/calls`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  showCall(organizationId: string, callId: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/calls/${callId}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  updateCall(
    organizationId: string,
    id: string,
    call: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(
        `${environment.apiUrl}/call-center/conversations/${id}/calls/${call}`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  updateCallStatus(
    organizationId: string,
    id: string,
    call: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(
        `${environment.apiUrl}/call-center/conversations/${id}/calls/${call}/status`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  // | ------------------------------|
  // | Conversation tabulation       |
  // | ------------------------------|
  storeTabulation(
    organizationId: string,
    id: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${id}/tabulations`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  // | ------------------------------|
  // | Conversation schedules        |
  // | ------------------------------|
  listReasons(
    organizationId: string,
    customToken = null,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversation-reasons`,
      {
        params,
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  availableTimes(
    organizationId: string,
    params = {},
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/available-times`,
      {
        params,
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  availableTimesByOperator(
    organizationId: string,
    params = {},
    customToken = null
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/available-times/by-operator`,
      {
        params,
        headers: {
          ...this.buildAuthToken(customToken),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  getSchedules(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/schedules`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  // | ------------------------------|
  // | Zipcode                       |
  // | ------------------------------|
  searchZipcode(zipcode: string): Observable<any> {
    return this.http.get(`https://viacep.com.br/ws/${zipcode}/json/`);
  }

  // | ------------------------------|
  // | Conversation feedback         |
  // | ------------------------------|
  sendFeedbackForm(
    organizationId: string,
    conversationId: string,
    data = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${conversationId}/feedback/send`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  // | ------------------------------|
  // | Conversation utis             |
  // | ------------------------------|
  parseConversation(data) {
    return {
      ...data,
      contact: this.parseContact(data?.contact),
    };
  }

  parseContact(data) {
    if (!data) {
      return null;
    }

    const firstNamePieces = String(data?.associate?.first_name).split(" ");
    const firstName =
      firstNamePieces.length > 0
        ? firstNamePieces[0]
        : data?.associate?.first_name;

    const [, ...lastNamePieces] = String(
      data?.associate?.last_name || ""
    ).split(" ");
    const lastName =
      firstNamePieces.length > 1
        ? lastNamePieces.join(" ")
        : data?.associate?.last_name;
    const response = {
      ...data,
      associate: {
        ...data?.associate,
        first_name: firstName,
        last_name: lastName,
        photo_url: `${environment.ecpUrl}${data?.associate?.photo}`,
      },
    };
    return response;
  }

  parseContactEcpLight(data) {
    if (!data) {
      return null;
    }

    const firstNamePieces = String(data?.first_name).split(" ");
    const firstName =
      firstNamePieces.length > 0 ? firstNamePieces[0] : data?.first_name;

    const [, ...lastNamePieces] = String(
      data?.associate?.last_name || ""
    ).split(" ");
    const lastName =
      firstNamePieces.length > 1 ? lastNamePieces.join(" ") : data?.last_name;

    const response = {
      id: data.contact_id,
      profile: data,
      associate: {
        ...data,
        first_name: firstName,
        last_name: lastName,
        photo_url: `${environment.ecpUrl}${data?.photo}`,
        profile: data,
      },
    };
    return response;
  }

  totemHasAvailability(
    organizationId: string,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/ecp/conversations/totem-has-availability`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  bvImportMailing(
    organizationId: string,
    endpoint = "import-warranty-replacement",
    data
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/neob/${endpoint}`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  medlevensohnImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/medlevensohn/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  thermasImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/thermas/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  serasaReversaoImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/serasa-reversao/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  serasaCanalRemotoImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/serasa-canal-remoto/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  serasaTelevendasImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/serasa-televendas/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  pvaImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/pva/mailings/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  pedeProntoImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/pedepronto/mailing`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  claroImport(
    organizationId: string,
    data,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/claro/import`,
      data,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  attachEmailMessageAddressToContact(
    organizationId: string,
    data = {}
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/emails/attach-contact-email-by-message`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  forwardMessages(
    organizationId: string,
    conversationId: string,
    data
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/${conversationId}/forward-messages`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  checkSaunaScheduleSameTime(
    organizationId: string,
    datetime: string,
    operator: string
  ): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(
        `${environment.apiUrl}/ecp/schedules-on-time?datetime=${datetime}&operator=${operator}`,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  updateGenesysConversation(
    organizationId: string,
    id: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(
        `${environment.apiUrl}/ecp/genesys/conversations/${id}`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  attachOperatorOnFeedback(
    organizationId: string,
    id: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(
        `${environment.apiUrl}/ecp/ura/send/feedback/${id}`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  downloadHtml(organizationId: string, message: string): Promise<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}/call-center/message-templates/download`,
        { message },
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
          responseType: "blob" as "json",
        }
      )
      .toPromise();
  }
}
