import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'
import { BaseService } from 'src/app/services/base.service'
import { AuthService } from 'src/app/services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  me (token: string = null): Observable<IApiResponse> {
    const headers = this.buildAuthToken()

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return this.http.get<IApiResponse>(`${environment.apiUrl}/me/`, { headers })
  }

  update (data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/me/`, data, {
      headers: this.buildAuthToken()
    })
  }

  photo (data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/me/photo/`, data, {
      headers: this.buildAuthToken()
    })
  }

  password (data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/me/password/`, data, {
      headers: this.buildAuthToken()
    })
  }

  totemLogin (data): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/ecp/totem/login`, data)
  }
}
