<div class="top--Nav fade-in-top">
  <div class="d-flex align-items-start">
    <div class="blue--Box">
      <a routerLink="/profile">
        <span class="user--Avatar">
          <img [src]="auth.getUser()?.photo_url" *ngIf="auth.getUser()?.photo_url" />
        </span>
      </a>
    </div>
    <div class="d-flex align-items-center p-2">
      <li class="default--Menuitem last">
        <a
          (click)="openNotificationBell()"
          class="nav-link ico--Alert -icon p-0"
          [ngClass]="{ active: !true }"
        >
          <fa-icon [icon]="faBellIcon"></fa-icon>
          <span *ngIf="showUnreadIcon()" class="unread"></span>
        </a>
      </li>

      <app-notification-message
        *ngIf="openMessageNotificationModal"
        [messagesNotificated]="messagesNotificated"
        [isLoading]="isLoadingNotifications"
        [page]="notificationsPage"
        (pageChange)="onNotificationsPageChanged($event)"
        [canShowLoadMoreNotifications]="canShowLoadMoreNotifications"
        [totalNotifications]="totalNotifications"
      >
      </app-notification-message>
    </div>
    <div class="p-1 message-ticker">
      <span class="welcome" translate [translateParams]="{ name: auth.getUser()?.first_name }">
        headers::greetings
      </span>
    </div>
    <div class="d-flex align-items-center ml-auto organization">
      <div *ngIf="!ecpOperator && _organization.slug !== 'claro'" class="contact-center--operator--status phone-status-section">
        <img id="sip-phone--Status" class="phone-status" src="../../../assets/img/phone-status/red.png"/>
      </div>
      <div
        class="contact-center--operator--status"
        *ngIf="!ecpOperator"
      >
        <button
          type="button"
          (click)="handleToggleStatus()"
          [ngClass]="operator?.custom_data?.administrative ? 'in_break' : operator?.status"
          class="blueone-button"
        >
          <fa-icon [icon]="faCircle"></fa-icon>
          {{
            operator?.custom_data?.administrative
              ? 'Administrativo'
              : ('call-center::operator::status::' + operator?.status | translate) +
                ' ' +
                (timeDifference && operator?.status === 'in_break' ? '(' + timeDifference + ')' : '')
          }}
        </button>
      </div>
      <app-organization-switcher *ngIf="!ecpOperator && userLoaded"></app-organization-switcher>
    </div>
  </div>
</div>

<app-operator-status-modal
  [(show)]="showOperatorStatusModal"
  (updated)="onUpdateOperatorStatus($event)"
></app-operator-status-modal>

<audio id="notificationAudio" muted src="/assets/sounds/notification.wav"></audio>
