<app-header
  class="top--Nav"
  *ngIf="auth.isLogged() && canShowPageStructure()"
  [ecpOperator]="isFromGenesys()"
  [userLoaded]="userLoaded"
></app-header>

<app-nav
  class="main--Menu"
  *ngIf="auth.isLogged() && canShowPageStructure()"
  [ecpOperator]="isFromGenesys()"
></app-nav>

<router-outlet></router-outlet>

<footer class="footer" *ngIf="auth.isLogged() && canShowPageStructure()">
  <div class="d-flex justify-content-center align-items-center">
    <img class="logo--Footer" src="./assets/img/brand/new/logo--DDR@2x.png" />
    <small class="d-block footer--Text">® {{ 'footer::all_rights_reserved' | translate }} {{ year }} </small>
  </div>
</footer>
<audio id="ringtoneAudioGlobal" muted loop src="../assets/sounds/ringtone-incoming.wav"></audio>
<app-receptive-call-alert
*ngIf = "activePage !== '/call-center/operator'"
>
</app-receptive-call-alert>