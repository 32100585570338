import { Component, Injector, OnInit } from '@angular/core'
import { BasePage } from 'src/app/components/base-page/base-page'
import { STATUS_TYPE } from 'src/app/interfaces/operator-status-log-v2'
import { AuthService } from 'src/app/services/auth.service'
import { OperatorsService } from '../../call-center/services/operators.service'
import { QueuesService } from 'src/app/pages/call-center/services/queues.service'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent extends BasePage implements OnInit {
  constructor (
    injector: Injector,
    private auth: AuthService,
    private operatorService: OperatorsService,
    private queueService: QueuesService
  ) {
    super(injector)
  }

  ngOnInit (): void {
    setTimeout(async () => {
      const custom_data = { administrative: false }

      try {
        const { data: operator } = await this.operatorService
          .mePromise(this._organization.id)

        Promise.all([
          await this.operatorService.updateStatus(this?._organization.id, operator?.id,
            {
              status: STATUS_TYPE['offline'],
              organization_id: this?._organization.id,
              operator_name: `${operator.user.first_name} ${operator.user.last_name}`
            }),
          await this.operatorService.update(this?._organization.id, operator?.id, { status: 'offline', custom_data }).toPromise(),
          await this.queueService.operatorQueueChangeState(this?._organization.id, null, false)
        ])
      } catch (error) {
        console.error(error)
      }

      this.auth.logout()
      window.location.href = '/'
    }, 100)
  }
}
