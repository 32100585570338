export const environment = {
  production: true,
  apiUrl: "https://api2020.b1app.com.br",
  apiUrlv2: "https://apiv2.b1app.com.br/v1",
  wsUrl: "wss://ws.b1app.com.br",
  ecpUrl: "https://ecp.b1app.com.br",
  ecpPortalUrl: "https://ecpv2.b1app.com.br/v1",
  customizationServiceUrl: "https://customization-service-prod.b1app.com.br/v1",
  knowledgeBaseServiceUrl: "https://knowledge-base.b1app.com.br/v1",
  apiVindi: "https://service-providers-prod.b1app.com.br/vindi/v1/",
  apiVindiSandBox: "https://sandbox-app.vindi.com.br/api/v1/",
  msDialerApi: "https://dialer.b1app.com.br/dialer",
  userMsDialer: "marco.tulio@ddrtech.com.br",
  passwordMsDialer: "692659d1-6660-4a16-9078-ea3e73a3fa41",
  apiFireReport: "https://report.b1app.com.br/report/v1",
  apiSip: "https://recordings-api-prod.b1app.com.br/v1",
  apiSipV2: "https://sip-api-v2.prd.b1app.com.br",
  notificationUrl: "https://notifications.prd.b1app.com.br",
  liveChatUrl: "https://apichat.b1app.com.br",
  dashboardSocket: "https://dashboard.b1app.com.br",
  ticketWorkflowUrl: "https://ticket.b1app.com.br/v1",
  apiVrPortal: "https://vr-portal-api.prd.b1app.com.br/api",
  vrPortalUrl: "https://portalvr.b1app.com.br",
  InfinityApi: "https://api.infinity.b1app.com.br/api",
  monitoringApi: "https://b1-monitoring.b1app.com.br",
};
