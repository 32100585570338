import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core'
import { BasePage } from 'src/app/components/base-page/base-page'
import { EventBusService } from 'ng-simple-event-bus'
import  { Router } from '@angular/router'
import { getCall } from 'src/app/services/currentIncomingCall.service'

@Component({
  selector: 'app-receptive-call-alert',
  templateUrl: './receptive-call-alert.component.html',
  styleUrls: ['./receptive-call-alert.component.scss']
})
export class ReceptiveCallComponentAlert extends BasePage implements OnInit {
  @ViewChild('acceptButton') acceptButton: ElementRef
  call: any
  audioRingtone: HTMLAudioElement
  constructor (
    injector: Injector,
    private event: EventBusService,
    private router: Router
  ) {
    super(injector)
    this.call = getCall()
  }

  async ngOnInit (): Promise<void> {
    this.audioRingtone = document.getElementById('ringtoneAudioGlobal') as HTMLAudioElement
    this.call = getCall()
    this.event.on('IncomingCall', (payload: any) => this.call = payload)
  }

  handleClick () {
    this.router.navigate(['/call-center/operator'])
  }
}
