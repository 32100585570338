import { Component, OnInit, Injector, Input } from '@angular/core'
import { environment } from 'src/environments/environment'
import { AuthService } from 'src/app/services/auth.service'
import * as moment from 'moment'
import { IOrganization } from 'src/app/interfaces/organization'
import { faBell, faPause, faPlay, faCircle } from '@fortawesome/free-solid-svg-icons'
import { IUser } from 'src/app/interfaces/user'
import { CallCenterOperatorStatusService } from 'src/app/services/call-center-operator-status.service'
import { OperatorsService } from 'src/app/pages/call-center/services/operators.service'
import { INotifications, notificationService } from 'src/app/services/notification.service'
import { IOperator } from 'src/app/interfaces/operator'
import { BasePage } from '../base-page/base-page'
import io from 'socket.io-client'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BasePage implements OnInit {
  @Input() ecpOperator: boolean
  @Input() userLoaded: boolean
  @Input() updated: any

  organization: IOrganization
  organizations: Array<IOrganization>
  greetings: string
  user: IUser

  callCenterOperatorStatus: string
  showCallCenterOperatorStatusToggler: boolean
  showOperatorStatusModal: boolean
  operator: IOperator

  faBellIcon = faBell
  faPause = faPause
  faPlay = faPlay
  faCircle = faCircle

  startAt: any
  timeDifference: any
  counterStatusInBreak: any
  currentTime: any
  timerInterval: any

  ws: any

  notificationAudio: HTMLAudioElement
  messagesNotificated: INotifications[] = []

  openMessageNotificationModal: boolean = false
  isLoadingNotifications: boolean
  notificationsPage: number
  notificationsPerPage: number
  totalNotifications: number
  canShowLoadMoreNotifications: boolean

  constructor (
    public auth: AuthService,
    private operatorStatusToggler: CallCenterOperatorStatusService,
    private operatorService: OperatorsService,
    private notificationService: notificationService,
    injector: Injector
  ) {
    super(injector)

    this.user = this.auth.getUser()
    this.watchOperatorStatusTogglerVisibility()
    this.showCallCenterOperatorStatusToggler = false
    this.isLoadingNotifications = false
    this.notificationsPage = 1
    this.notificationsPerPage = 0
    this.totalNotifications = 0
    this.canShowLoadMoreNotifications = true
    this.handleInBreakStatusCounter()
  }

  async ngOnInit () {
    this.connectWs()
    this.getOperator()
    this.callCenterOperatorStatus = 'online'
    this.notificationAudio = document.getElementById('notificationAudio') as HTMLAudioElement
    this.notificationAudio.volume = 0.4
    this.getNotifications()
  }

  async getNotifications (updating = false) {
    this.isLoadingNotifications = true
    const response = await this.notificationService.getNotifications(
      this.user.id,
      this._organization.id,
      this.notificationsPage
    )
    this.notificationsPage = response.page
    this.totalNotifications = response.totalRows
    this.notificationsPerPage = response.perPage
    if (updating) {
      this.messagesNotificated = response.data
    } else {
      this.messagesNotificated = [...this.messagesNotificated, ...response.data]
    }
    this.isLoadingNotifications = false
    if (this.notificationsPage * this.notificationsPerPage > this.totalNotifications) {
      this.canShowLoadMoreNotifications = false
    }
  }

  onNotificationsPageChanged (page: number) {
    this.notificationsPage = page
    this.getNotifications()
  }

  watchOperatorStatusTogglerVisibility (): void {
    this.operatorStatusToggler.showStatus$.subscribe((res) => {
      this.showCallCenterOperatorStatusToggler = res
      this.getOperator()
    })
  }

  handleToggleStatus (): void {
    this.showOperatorStatusModal = true
  }

  getOperator () {
    this.operatorService.showOperatorByUser(this._organization.id, this.user.id).subscribe((res) => {
      this.operator = res.data
    })
  }

  async onUpdateOperatorStatus ({ operator, toUpdateCounter }) {
    this.operator = operator
    this.restartCounter(toUpdateCounter)
  }

  getLastInBreakStatus (): Promise<any> {
    return new Promise((resolve, reject) => {
      this.operatorService.getLastInBreakStatus(this._organization.id, this.user.id).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      )
    })
  }

  async handleInBreakStatusCounter () {
    return this.getLastInBreakStatus().then((data) => {
      this.restartCounter(data)
    })
  }

  restartCounter (operator: any) {
    this.stopTimer()
    this.initTimer(operator)
  }

  initTimer (operatorData: any): void {
    if (operatorData.status === 'ON_BREAK') {
      this.startAt = operatorData?.started_at
      this.startTimer()
    }
  }

  getTimeDifference (current, start): string {
    return moment.utc(current.diff(start)).format('HH:mm:ss')
  }

  startTimer (): void {
    this.timerInterval = setInterval(() => {
      this.timeDifference = this.getTimeDifference(moment(), this.startAt)
    }, 1000)
  }

  stopTimer (): void {
    clearInterval(this.timerInterval)
    this.timeDifference = '00:00:00'
  }

  openNotificationBell () {
    this.openMessageNotificationModal = !this.openMessageNotificationModal
    const notificationNotReaded = this.messagesNotificated.filter((notification) => !notification.isReaded)
    if (notificationNotReaded != undefined && notificationNotReaded != null) {
      notificationNotReaded.forEach((notification) => {
        this.notificationService
          .markReadForNotifications(this.user.id, this._organization.id, notification.id)
          .then((_) => {
            const index = this.messagesNotificated.indexOf(notification)
            if (index !== -1) {
              this.messagesNotificated[index].isReaded = true
            }
          })
          .catch((err) => console.log(err))
      })
    }
  }

  connectWs () {
    this.ws = io(environment.wsUrl, {
      query: {
        room: ['notification::create'],
        authorization: this._auth.getToken().token
      }
    })

    this.ws.once('connect', () => {
      this.ws.on('new_message', async (payload: INotifications) => {
        if (payload.organizationId === this._organization.id && payload?.userId === this.user.id) {

          // definindo tempo para buscar novas notificações

          setTimeout(async () => {
            await this.getNotifications(true)
            this.notificationAudio.muted = false
            this.notificationAudio.play()
          }, 4000);
        }
      })
    })
  }

  showUnreadIcon (): boolean {
    return this.messagesNotificated.some((el) => !el.isReaded)
  }
}
