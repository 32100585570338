import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CallCenterOperatorStatusService {
  private showStatusToggler = new Subject<boolean>()
  showStatus$ = this.showStatusToggler.asObservable()

  constructor () {
    //
  }

  toggleShowStatusToggler (show: boolean): void {
    this.showStatusToggler.next(show)
  }
}
