import { Injector } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AuthService } from 'src/app/services/auth.service'
import { TranslationService } from 'src/app/services/translation.service'
import swal from 'sweetalert2'
import { FormBuilder } from '@angular/forms'
import { IOrganization } from 'src/app/interfaces/organization'
import { ActivatedRoute, Router } from '@angular/router'
import { I18nService } from 'src/app/services/i18n.service'
import { SettingsService, OrganizationConfig } from 'src/app/pages/core/profile/services/settings.service'

export abstract class BasePage {
  _auth: AuthService
  _toast: ToastrService
  _settingsService: SettingsService
  _organizationConfig: OrganizationConfig
  _translation: TranslationService
  _swal: any
  _formBuilder: FormBuilder
  _organization: IOrganization
  _activatedRoute: ActivatedRoute
  _router: Router
  _i18n: I18nService
  _timezone: string

  constructor (injector: Injector) {
    this._toast = injector.get(ToastrService)
    this._auth = injector.get(AuthService)
    this._settingsService = injector.get(SettingsService)
    this._translation = injector.get(TranslationService)
    this._formBuilder = injector.get(FormBuilder)
    this._swal = swal
    this._organization = this._auth.getOrganization()
    this._activatedRoute = injector.get(ActivatedRoute)
    this._router = injector.get(Router)
    this._i18n = injector.get(I18nService)
    this._timezone = this._i18n.getTimezone()
    this._organizationConfig = this._settingsService.getLocallyOrganizationConfig()
  }

  async _confirmDelete (message = null) {
    const title = await this._translation.get('generic::modal::delete::title')
    const text = message || await this._translation.get('generic::modal::delete::message')
    const confirmButtonText = await this._translation.get('generic::forms::buttons::delete')
    const cancelButtonText = await this._translation.get('generic::forms::buttons::cancel')

    return this._swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn--Danger ml-2',
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true
    })
  }
}
