import { IOrganization } from '../interfaces/organization'
import { IUser } from '../interfaces/user'
import { AclModule, AclService } from '../services/acl.service'

export class AclHelper {
  private organization: IOrganization
  private user: IUser
  private aclService: AclService

  static create (organization: IOrganization, user: IUser) {
    return new AclHelper(organization, user)
  }

  constructor (organization: IOrganization, user: IUser) {
    this.organization = organization
    this.user = user
    this.aclService = AclService.create()
  }

  canShowModule (module: AclModule): boolean {
    const allowedModules = this.aclService.getAllowedModulesByOrganizationSlug(
      this.organization.slug,
      this.user
    )
    return allowedModules.includes(module)
  }
}
