import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  _auth: AuthService

  constructor (private auth: AuthService) {
    this._auth = this.auth
  }

  buildAuthToken (customToken = null): { Authorization: string } {
    const token = this.auth.getToken()
    return { Authorization: customToken || `${token?.type} ${token?.token}` }
  }

  buildAuthTokenV2 (customToken = null): { Authorization: string } {
    const token = this.auth.getV2Token()
    return { Authorization: customToken || `Bearer ${token}` }
  }
}
