import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/services/base.service'
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/services/auth.service'
import { Observable, BehaviorSubject } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class QueuesService extends BaseService {
  private messageSource = new BehaviorSubject(false)
  currentMessage = this.messageSource.asObservable()

  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  handleUpdateQueue (message: any) {
    this.messageSource.next(message)
  }

  list (organizationId: string, params: any): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queues`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  publicConversations (organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queue-conversations`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  conversationsByOperator (organizationId: string, user: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queue-conversations/operator`, {
      params: { user_id: user, ...params },
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  conversationsByQueue (organizationId: string, queue: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queue-conversations/queue`, {
      params: { queue_id: queue, ...params },
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  operatorQueueState (organizationId: string, queue: string, operatorId: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/queue-operator-login`, {
      params: {
        queueId: queue,
        operatorId
      },
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    }).toPromise()
  }

  operatorQueueChangeState (organizationId: string, queue: string, state: boolean): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/queue-operator-login`, {
      queueId: queue,
      state
    }, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    }).toPromise()
  }
}
