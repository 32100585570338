<div class="position-relative" dropdown>
  <button type="button" dropdownToggle class="d-flex align-items-center">
    {{ organization?.name || 'BlueOne' }}
    <fa-icon class="ml-2" [icon]="faChevronDownIcon"></fa-icon>
  </button>
  <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
    <li *ngFor="let item of organizations">
      <a class="dropdown-item" href="javascript:;" (click)="changeOrganization(item)">
        {{ item?.name }}
      </a>
    </li>
  </ul>
</div>
