<div class="notifications-container">
  <span class="triangle"></span>
  <div class="notifications-header">
    <h4>Notificações ({{ totalNotifications || 0 }})</h4>
  </div>
  <div class="notification-body">
    <div
      class="notification-item"
      *ngFor="let notification of messagesNotificated"
      [ngClass]="{ 'has-link': !!notification.actionUrl }"
    >
      <div class="notification-title">
        <div class="title-group">
          <span class="title" *ngIf="notification?.title">{{ notification?.title }}</span>
          <span class="date">{{ getCalendarDate(notification?.createdAt) }}</span>
        </div>
        <span *ngIf="!notification.isReaded" class="unread"></span>
      </div>
      <div class="notification-content">
        <span>{{ notification?.message }}</span>
      </div>
    </div>
  </div>
  <div class="notifications-load-more" *ngIf="canShowLoadMoreNotifications">
    <button type="button" (click)="handleLoadMore()" [disabled]="isLoading">
      {{ isLoading ? 'Carregando...' : 'Carregar mais' }}
    </button>
  </div>
</div>
