import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";
import { AuthService } from "src/app/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { IApiResponse } from "src/app/interfaces/api-response";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ContactsService extends BaseService {
  private statusSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  status = this.statusSubject.asObservable();

  oncloseDetail = (value: boolean) => {
    this.statusSubject.next(value);
  };

  constructor(auth: AuthService, private http: HttpClient) {
    super(auth);
  }

  list(organizationId, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/contacts/`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        "B1-Organization": organizationId,
      },
    });
  }

  listLightEcp(organizationId, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/ecp/contacts/light`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  store(organizationId: string, data): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/contacts`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  show(
    organizationId: string,
    id: string,
    originRequested?: string,
    sync: any = false
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/contacts/${id}`, {
      headers: {
        ...this.buildAuthToken(),
        "B1-Organization": organizationId,
      },
      params: {
        origin: originRequested,
        sync: sync,
      },
    });
  }

  showContactByReference(organizationId: string, reference_id: string) {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/ecp/workflow/contact/${reference_id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  findMinorParents(
    organizationId: string,
    id: string
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/contacts/minor/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  update(
    organizationId: string,
    id: string,
    data,
    originRequested?: string,
    params?: any
  ): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/contacts/${id}`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
        params: {
          origin: originRequested,
          ...(params || {}),
        },
      }
    );
  }

  updatePromise(
    organizationId: string,
    id: string,
    data,
    params?: {}
  ): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(`${environment.apiUrl}/contacts/${id}`, data, {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
        params: {
          ...(params || {}),
        },
      })
      .toPromise();
  }

  photo(organizationId: string, id: string, data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/contacts/${id}/photo`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  delete(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(
      `${environment.apiUrl}/contacts/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  associate(
    organizationId: string,
    id: string,
    data
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/contacts/${id}/associate`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  dissociate(
    organizationId: string,
    id: string,
    data
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/contacts/${id}/dissociate`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  parseContact(organizationSlug = null, data = null, dataEcp = null) {
    let contact = data;
    if (organizationSlug === "ecp") {
      contact = {
        ...data,
        profile: data?.associate?.id ? data?.associate : data?.profile,
        photo_url: `${environment.ecpUrl}${data?.associate?.photo}`,
        ecp: dataEcp || {},
        gender: data?.associate?.gender,
      };
    }

    const displayName = String(
      `${contact?.profile?.first_name} ${contact?.profile?.last_name}`
    ).trim();
    const mainPhone = contact?.phones?.[0]?.phone || null;

    return {
      ...contact,
      display_name: displayName,
      main_phone: mainPhone,
    };
  }

  exportAuphafood(organizationId: string): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/auphafood/contacts/export`,
      {},
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  exportThermasProducts(
    organizationId: string,
    startDate: string,
    endDate: string
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/thermas/products/report?`,
      { startDate, endDate },
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  contactToggle(
    organizationId: string,
    contactId: string,
    qs: any,
    data: any = {}
  ): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/contacts/${contactId}/toggle?toggle=${qs}`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  getPassword(organizationId, id: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/user-caml/${id}/auth`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }
}
