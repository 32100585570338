import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/services/base.service'
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/services/auth.service'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'

export class ConversationService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  getAll (organizationId: string, params = {}): Promise<IApiResponse> {
    return  this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/conversation-reasons`, {
      params,
      headers: {
        ...this.buildAuthToken(), 'B1-Organization': organizationId
      }
    }).toPromise()
  }

  store (organizationId: string, data): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/call-center/conversation-reasons`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  getCalls (conversationId: string, organizationId: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/conversations/${conversationId}/calls`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  getCallRecording (organizationId: string, conversationId: string, callId: string): Promise<any> {
    return this.http.get(`${environment.apiUrl}/call-center/conversations/${conversationId}/calls/${callId}/recording`, {
      responseType: 'arraybuffer',
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    }).toPromise()
  }

  update (organizationId: string, id: string, data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/call-center/conversation-reasons/${id}`, data, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  delete (organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${environment.apiUrl}/call-center/conversation-reasons/${id}`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }
}
