import { IOrganization } from "src/app/interfaces/organization";
import { Registerer, UserAgent, SIPExtension } from "sip.js/lib/api";
import { URI } from "sip.js/lib/core";
import { IOperator } from "src/app/interfaces/operator";
import { VoiceHelper } from "../../helpers/voice-helper";
import { OrganizationIntegration } from "src/app/interfaces/organization-integration";

interface VoiceSipConnectorParams {
  extensionUsername: string;
  extensionPassword: string;
  firstName?: string;
  lastName?: string;
  iceServers?: any;
}

class VoiceSipConnector {
  private instance: { ua: UserAgent; registerer: Registerer };
  private reconnectionInterval: NodeJS.Timeout = null;
  public reconnecting: boolean = false;

  private red: string = "../../../assets/img/phone-status/red.png";
  private green: string = "../../../assets/img/phone-status/green.png";
  private orange: string = "../../../assets/img/phone-status/orange.png";

  async connect(
    params: VoiceSipConnectorParams,
    organization: IOrganization,
    onInviteCallBack?: any,
    operator?: IOperator,
    voiceServerConfig?: OrganizationIntegration
  ) {
    if (this.instance?.ua && this.instance?.registerer) {
      return this;
    }

    if (!params.extensionUsername || !params.extensionPassword) {
      return null;
    }

    const voiceHelper = VoiceHelper.create(organization, voiceServerConfig);

    const webSocketServer = voiceHelper.fetchSipServer();
    const userAgentUri = new URI(
      "sip",
      params.extensionUsername,
      voiceHelper.fetchSipDomain(),
      voiceHelper.fetchVoiceDriver() === "freeswitch" ? 5060 : 5160
    );

    const ua = new UserAgent({
      authorizationUsername: params.extensionUsername,
      authorizationPassword: params.extensionPassword,
      uri: userAgentUri,
      logLevel: "debug",
      allowLegacyNotifications: true,
      transportOptions: {
        server: webSocketServer,
        traceSip: true,
        maxReconnectionAttempts: 0,
      },
      sipExtensionReplaces: SIPExtension.Supported,
      viaHost: "app.b1app.com.br",
      // autoStop: true,
      userAgentString: "BlueOne Voice Server",
      displayName: `${params?.firstName || ""} ${params?.lastName || ""}`,
      contactName: params.extensionUsername,
      contactParams: {
        transport: "wss",
        "rtcweb-breaker": "no",
      },
      delegate: {
        onConnect: () => {
          console.debug("[B1_SIP] SIP connection established");
          document
            .getElementById("sip-phone--Status")
            ?.setAttribute("src", this.green);
        },
        onDisconnect: () => {
          console.debug("[B1_SIP] SIP connection finished");
          document
            .getElementById("sip-phone--Status")
            ?.setAttribute("src", this.red);
          this.instance.registerer = null;
          this.instance.ua = null;
          this.reconnecting = false;

          document
            .getElementById("sip-phone--Status")
            .classList.add("reconnecting");
          document
            .getElementById("sip-phone--Status")
            ?.setAttribute("src", this.orange);

          this.reconnectionInterval = setInterval(async () => {
            console.warn("[B1_SIP] Starting reconnect");
            if (this.reconnecting) {
              return;
            }

            this.reconnecting = true;

            try {
              console.warn(
                "[B1_SIP] Recursively trying to reconnect on websocket"
              );
              await this.connect(
                params,
                organization,
                onInviteCallBack,
                operator,
                voiceServerConfig
              );
              clearInterval(this.reconnectionInterval);
              console.warn("[B1_SIP] Reconnected");
              this.reconnecting = false;
              document
                .getElementById("sip-phone--Status")
                .classList.remove("reconnecting");
            } catch (error) {
              console.warn("[B1_SIP] Failed to reconnect");
              this.reconnecting = false;
            }
          }, 4000);
        },
        onInvite: onInviteCallBack,
      },
      sessionDescriptionHandlerFactoryOptions: {
        peerConnectionConfiguration: {
          iceServers: params?.iceServers?.data || [],
          iceTransportPolicy: "all",
        },
      },
    });
    const registerer = new Registerer(ua);

    await ua.start();
    await registerer.register();
    this.instance = { ua, registerer };
    return this;
  }

  async disconnect(): Promise<boolean> {
    if (this.instance?.ua && this.instance?.registerer) {
      await this.instance.registerer
        .unregister()
        .catch((error) => console.log(error));
      this.instance = null;

      return true;
    }

    return false;
  }

  getInstance() {
    return this.instance;
  }
}

export default new VoiceSipConnector();
