import { NgModule } from '@angular/core'
import { HeaderComponent } from './header.component'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { RouterModule } from '@angular/router'
import { OrganizationSwitcherModule } from '../organization-switcher/organization-switcher.module'
import { OperatorStatusModalModule } from '../operator-status-modal/operator-status-modal.module'
import { OperatorsService } from 'src/app/pages/call-center/services/operators.service'
import { NotificationMessageComponent } from './notification-message/notification-message.component'

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule, OrganizationSwitcherModule, OperatorStatusModalModule],
  providers: [OperatorsService],
  declarations: [HeaderComponent, NotificationMessageComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
