import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { IOrganization } from 'src/app/interfaces/organization'
import { faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { AuthService as AuthApiService } from 'src/app/pages/core/auth/services/auth.service'

@Component({
  selector: 'app-organization-switcher',
  templateUrl: './organization-switcher.component.html',
  styleUrls: ['./organization-switcher.component.scss']
})
export class OrganizationSwitcherComponent implements OnInit {
  organizations: Array<IOrganization>
  organization: IOrganization
  faChevronDownIcon: IconDefinition

  constructor (
    private auth: AuthService,
    private authService: AuthApiService
  ) {
    this.organization = this.auth.getOrganization()
    this.faChevronDownIcon = faChevronDown
  }

  ngOnInit (): void {
    this.organizations = this.sortOrganizationsByName(this.auth.getOrganizations())
  }

  sortOrganizationsByName (data: IOrganization[]): IOrganization[] {
    return data.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      }

      if (a.name < b.name) {
        return -1
      }

      return 0
    })
  }

  async changeOrganization (organization): Promise<any> {
    this.auth.setOrganization(organization)
    localStorage.removeItem('@b1/operator_search_results')
    await this.fetchV2Token()
    window.location.reload()
  }

  async fetchV2Token () {
    try {
      const { token } = await this.authService.getToken()
      this.auth.setV2Token(token)
    } catch (error) {
      console.error(error)
    }
  }
}
