import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { IApiResponse } from "../interfaces/api-response";
import { ProfileService } from "../pages/core/profile/services/profile.service";

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private profileService: ProfileService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot) {
    if (!!next.queryParams.token) {
      try {
        const data = await this.login(next.queryParams.token)

        this.authService.setUser(data.data)
        this.authService.setToken(data.token)
        this.authService.setLang('pt')
      } catch (error) {
        window.location.href = "/auth/login";
      }
    }

    if (!this.authService.isLogged()) {
      window.location.href = "/auth/login";
    }

    return this.authService.isLogged();
  }

  async login(token: string): Promise<IApiResponse> {
    return new Promise((resolve) => {
      this.profileService.me(token).subscribe(
        (res) => resolve(res),
        () => resolve(null)
      );
    });
  }
}
