<div class="receptive-call" *ngIf="call?.invitation">
  <div class="receptive-call--inner">
    <div class="receptive-call--title">
      Recebendo chamada
    </div>
    <div class="receptive-call--actions">
      <button #acceptButton type="button" (click)="handleClick()">
        Seguir para tela operador
      </button>
    </div>
  </div>
</div>
