import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/services/base.service'
import { HttpClient } from '@angular/common/http'
import { AuthService } from 'src/app/services/auth.service'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  list (organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/organizations/integrations`, {
      params,
      headers: {
        'B1-Organization': organizationId,
        ...this.buildAuthToken()
      }
    })
  }

  store (organizationId: string, data): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/organizations/integrations`, data, {
      headers: {
        'B1-Organization': organizationId,
        ...this.buildAuthToken()
      }
    })
  }

  update (organizationId: string, id: string, data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/organizations/integrations/${id}`, data, {
      headers: {
        'B1-Organization': organizationId,
        ...this.buildAuthToken()
      }
    })
  }

  authorize (organizationId: string, provider: string, data = null): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/integrations/${provider}/authorize`, data, {
      headers: {
        'B1-Organization': organizationId,
        ...this.buildAuthToken()
      }
    })
  }

  delete (organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${environment.apiUrl}/organizations/integrations/${id}`, {
      headers: {
        'B1-Organization': organizationId,
        ...this.buildAuthToken()
      }
    })
  }
}
