import { Component, Injector, Input, OnInit } from '@angular/core'
import { BasePage } from '../base-page/base-page'
import { AclModule } from '../../services/acl.service'
import { AclHelper } from '../../helpers/acl.helper'

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent extends BasePage implements OnInit {
  @Input() ecpOperator: boolean
  aclHelper: AclHelper
  allowedModules: AclModule[]
  modules

  constructor (injector: Injector) {
    super(injector)
    this.modules = AclModule
  }

  ngOnInit (): void {
    const user = this._auth.getUser()
    const organization = this._auth.getOrganization()
    this.aclHelper = AclHelper.create(organization, user)
    this.allowedModules = this.getAllowedModules()
  }

  getAllowedModules (): AclModule[] {
    const modules = this.getModules() as AclModule[]
    const allowedModules = modules.filter((moduleName) => this.aclHelper.canShowModule(moduleName))
    return allowedModules
  }

  getModules (): string[] {
    return Object.keys(AclModule).filter((key) => isNaN(Number(key)))
  }
}
