import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'
import { BaseService } from 'src/app/services/base.service'
import { AuthService } from 'src/app/services/auth.service'
import { OrganizationIntegration } from 'src/app/interfaces/organization-integration'

export interface OrganizationConfig {
  id: number
  organization_id: string
  tabulation_mode: 'unique' | 'multiple'
}

export interface OrganizationConfig {
  id: number
  organization_id: string
  tabulation_mode: 'unique' | 'multiple'
}

export interface OrganizationConfig {
  id: number
  organization_id: string
  tabulation_mode: 'unique' | 'multiple'
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {
  organizationConfigKey = '@blueone/organization_config'
  voiceServerConfigKey = '@blueone/voice_server'

  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  update (data): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(`${environment.apiUrl}/me/settings`, data, {
      headers: this.buildAuthToken()
    })
  }

  getLanguages (): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/languages`, {
      headers: this.buildAuthToken()
    })
  }

  getOrganizationConfig (organizationId: string): Promise<OrganizationConfig> {
    return this.http.get<OrganizationConfig>(`${environment.apiUrl}/me/organizations/config`, {
      headers: {
        ...this.buildAuthToken(),
        'b1-organization': organizationId
      }
    }).toPromise()
  }

  saveLocallyOrganizationConfig (organizationConfig: OrganizationConfig): void {
    localStorage.setItem(this.organizationConfigKey, JSON.stringify(organizationConfig))
  }

  getLocallyOrganizationConfig (): OrganizationConfig {
    const organizationConfig = localStorage.getItem(this.organizationConfigKey)
    return JSON.parse(organizationConfig)
  }

  removeLocallyOrganizationConfig (): void {
    localStorage.removeItem(this.organizationConfigKey)
  }

  saveLocallyVoiceServerConfig (organizationConfig: OrganizationIntegration): void {
    localStorage.setItem(this.voiceServerConfigKey, JSON.stringify(organizationConfig))
  }

  getLocallyVoiceServerConfig (): OrganizationIntegration {
    const organizationIntegration = localStorage.getItem(this.voiceServerConfigKey)
    return JSON.parse(organizationIntegration)
  }

  removeLocallyVoiceServerConfig (): void {
    localStorage.removeItem(this.voiceServerConfigKey)
  }
}
