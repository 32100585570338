import { Injectable } from "@angular/core";
import { IUser } from "../interfaces/user";

type OrganizationAllowedModules = {
  [key: string]:
    | AclModule[]
    | ((roles?: string[], user?: IUser) => AclModule[]);
};

export enum AclModule {
  DASHBOARD = "DASHBOARD",
  CONTACTS = "CONTACTS",
  ACCOUNTS = "ACCOUNTS",
  CALENDAR = "CALENDAR",
  LEADS = "LEADS",
  OPPORTUNITIES = "OPPORTUNITIES",
  CONTACT_CENTER_OPERATOR = "CONTACT_CENTER_OPERATOR",
  CONTACT_CENTER_ADMIN = "CONTACT_CENTER_ADMIN",
  CONTACT_CENTER_CONVERSATIONS = "CONTACT_CENTER_CONVERSATIONS",
  SERVICE_SCHEDULER = "SERVICE_SCHEDULER",
  KNOWLEDGE_BASE = "KNOWLEDGE_BASE",
  PRODUCTS = "PRODUCTS",
  PHOTOS = "PHOTOS",
  COST_CENTER = "COST_CENTER",
  RECORDINGS = "RECORDINGS",
  MONITORINGS = "MONITORINGS",
}

const CRM_MODULES = [
  AclModule.CONTACTS,
  AclModule.ACCOUNTS,
  AclModule.CALENDAR,
];
const SALES_MODULES = [AclModule.LEADS, AclModule.OPPORTUNITIES];
const CONTACT_CENTER_MODULES = [
  AclModule.CONTACT_CENTER_OPERATOR,
  AclModule.CONTACT_CENTER_ADMIN,
  AclModule.CONTACT_CENTER_CONVERSATIONS,
];
const DEFAULT_MODULES = [].concat(CRM_MODULES, SALES_MODULES);

@Injectable({ providedIn: "root" })
export class AclService {
  private organizationsAllowedModules: OrganizationAllowedModules;

  static create() {
    return new AclService();
  }

  constructor() {
    this.organizationsAllowedModules = this.fetchAllowedModules();
  }

  fetchAllowedModules(): OrganizationAllowedModules {
    return {
      auphafood: [AclModule.PRODUCTS].concat(
        CONTACT_CENTER_MODULES,
        CRM_MODULES,
        SALES_MODULES
      ),
      blueone: [AclModule.DASHBOARD].concat(
        CONTACT_CENTER_MODULES,
        SALES_MODULES,
        CRM_MODULES
      ),packinggroup: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          // AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      cpfl: (roles) => {
        const modules = [AclModule.DASHBOARD].concat(CRM_MODULES);
        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }
        if (!roles.includes("member")) {
          modules.push(AclModule.CONTACT_CENTER_ADMIN);
          modules.push(AclModule.CONTACT_CENTER_CONVERSATIONS);
        }
        return modules;
      }, // brisa experience
      ecp: (roles, user) => {
        const modules = [
          AclModule.KNOWLEDGE_BASE,
          AclModule.CONTACT_CENTER_CONVERSATIONS,
        ].concat(CRM_MODULES);
        const saunaAdminUsers = [
          "felipep@ecp.org.br",
          "livia@ecp.org.br",
          "elizabete@ecp.org.br",
        ];
        const serviceSchedulerUsers = [
          "altair.santos@ecp.org.br",
          "carlos.arruda@ecp.org.br",
          "doraci.santos@ecp.org.br",
          "fernanda.santos@ecp.org.br",
          "genesio.cruz@ecp.org.br",
          "jose.bastos@ecp.org.br",
          "maria.nascimento@ecp.org.br",
          "nivea.souza@ecp.org.br",
          "nino.brazil@ecp.org.br",
          "rafael.ferreira@ecp.org.br",
          "rosangela.sousa@ecp.org.br",
          "operador4@ecp.org.br",
        ];

        if (
          saunaAdminUsers.includes(user.email) ||
          roles.includes("totem") ||
          roles.includes("tv")
        ) {
          return [AclModule.CONTACT_CENTER_ADMIN];
        }

        if (serviceSchedulerUsers.includes(user.email)) {
          return [AclModule.SERVICE_SCHEDULER];
        }

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_OPERATOR
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      },
      jaguar: (roles) => {
        const modules = [].concat(CRM_MODULES);

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        if (!roles.includes("member")) {
          modules.push(
            AclModule.CONTACT_CENTER_CONVERSATIONS,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.DASHBOARD
          );
        }

        return modules;
      },
      mapfre: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      "mapfre-nissan-renault": (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      cielo: (roles) => {
        const modules = [].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_CONVERSATIONS
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      }, // neobpo
      neobpo: (roles) => {
        const modules = [].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_CONVERSATIONS
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      }, // maxtrack
      medlevensohn: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      migaloo: [AclModule.PRODUCTS].concat(CRM_MODULES, SALES_MODULES),
      pedepronto: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_OPERATOR,
          AclModule.CONTACT_CENTER_CONVERSATIONS,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      "pedepronto-teste": (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_OPERATOR,
          AclModule.CONTACT_CENTER_CONVERSATIONS,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      pva: (roles) => {
        const modules = [AclModule.CONTACT_CENTER_OPERATOR].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_CONVERSATIONS
          );
        }

        return modules;
      },
      vr: (roles) => {
        const modules = [
          AclModule.LEADS,
          AclModule.OPPORTUNITIES,
          AclModule.DASHBOARD,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      claro: (roles) => {
        let modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
          AclModule.DASHBOARD,
          AclModule.MONITORINGS,
        ].concat(CRM_MODULES);

        if (roles.includes("member")) {
          return [AclModule.CONTACT_CENTER_OPERATOR, AclModule.CONTACT_CENTER_CONVERSATIONS];
        }

        if (roles.includes("manager")) {
          return [AclModule.MONITORINGS, AclModule.DASHBOARD];
        }

        if (roles.includes("admin")) {
          return [AclModule.DASHBOARD].concat(
            CRM_MODULES,
            CONTACT_CENTER_MODULES
          );
        }

        return modules;
      },
      serasa: (roles) => {
        const modules = [].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_CONVERSATIONS,
            AclModule.CONTACT_CENTER_ADMIN
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      },
      "serasa-reversao": (roles) => {
        const modules = [AclModule.CONTACT_CENTER_OPERATOR].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_CONVERSATIONS
          );
        }

        return modules;
      },
      "serasa-televendas": (roles) => {
        const modules = [AclModule.PRODUCTS].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_CONVERSATIONS,
            AclModule.CONTACT_CENTER_ADMIN
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      },
      workflow: (roles) => {
        const modules = [AclModule.CONTACT_CENTER_CONVERSATIONS].concat(
          CRM_MODULES
        );

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_OPERATOR
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      },
      thermas: (roles) => {
        const modules = [].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(
            AclModule.DASHBOARD,
            AclModule.CONTACT_CENTER_CONVERSATIONS,
            AclModule.CONTACT_CENTER_ADMIN,
            AclModule.CONTACT_CENTER_OPERATOR
          );
        }

        if (!roles.includes("manager")) {
          modules.push(AclModule.CONTACT_CENTER_OPERATOR);
        }

        return modules;
      },
      emerson: [AclModule.PHOTOS].concat(CONTACT_CENTER_MODULES, CRM_MODULES),
      caml: (roles) => {
        const modules = [
          AclModule.CONTACTS,
          AclModule.ACCOUNTS,
          AclModule.CALENDAR,
          AclModule.COST_CENTER,
        ];

        // if (roles.includes("admin") || roles.includes("manager")) {
        //   modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN, AclModule.COST_CENTER);
        // }

        return modules;
      },
      brasilcap: [AclModule.CONTACT_CENTER_CONVERSATIONS],
      marsh: [AclModule.DASHBOARD, AclModule.RECORDINGS],
      ibm: [AclModule.DASHBOARD].concat(CRM_MODULES, CONTACT_CENTER_MODULES),
      coamo: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      },
      allianz: (roles) => {
        const modules = [
          AclModule.CONTACT_CENTER_CONVERSATIONS,
          AclModule.CONTACT_CENTER_OPERATOR,
        ].concat(CRM_MODULES);

        if (!roles.includes("member")) {
          modules.push(AclModule.DASHBOARD, AclModule.CONTACT_CENTER_ADMIN);
        }

        return modules;
      }
    };
  }

  getAllowedModulesByOrganizationSlug(
    organizationSlug: string,
    user: IUser
  ): AclModule[] {
    const userRoleSlugs = user.roles.map((userRole) => userRole.slug);
    let allowedModules = this.organizationsAllowedModules[organizationSlug];
    if (typeof allowedModules === "function")
      allowedModules = allowedModules(userRoleSlugs, user);
    if (!allowedModules) return DEFAULT_MODULES;
    return allowedModules;
  }
}
