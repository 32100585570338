import { IContact } from './contact'
import { IUser } from './user'
import { IOrganization } from './organization'
import { IConversationMessage } from './conversation-message'
import { IConversationCall, IConversationCallDirections } from './conversation-call'
import { IConversationChannel } from './conversation-channel'
import { IConversationTabulation } from './conversation-tabulation'
import { IInteraction } from './interaction'
import { ITicket } from './ticket'
import { IConversationPing } from './conversation-ping'
import { IOperator } from './operator'
import { IConversationQueue } from './conversation-queue'

export enum EConversationStartedBy {
  Mailing = 'mailing',
  Customer = 'customer',
  Operator = 'operator'
}

export enum EConversationStatus {
  Queued = 'queued',
  InProgress = 'in_progress',
  Finished = 'finished',
  Canceled = 'canceled'
}

export enum EConversationPriority {
  Low = 'low',
  Normal = 'normal',
  High = 'high',
  Immediate = 'immediate'
}

export interface IConversation {
  id?: string
  queue_id?: string
  queue?: IConversationQueue
  organization_id?: string
  contact_id?: string
  user_id?: string
  channel_id?: number
  channel?: IConversationChannel
  operators?: Array<IOperator>
  started_by?: EConversationStartedBy
  duration?: string
  direction?: string
  started_at?: Date | string
  finished_at?: Date | string
  contact_data?: any
  created_at?: Date | string
  updated_at?: Date | string
  status?: EConversationStatus
  priority?: EConversationPriority
  organization?: IOrganization
  contact?: IContact
  user?: IUser
  messages?: Array<IConversationMessage>
  calls?: Array<IConversationCall>
  tabulation?: IConversationTabulation
  protocol?: string
  ticket?: ITicket
  pings?: Array<IConversationPing>
  [key: string]: any
  created_by?: string
}
export interface IMailing {
  id: string
  organization_id: string
  user_id: string
  mailing_at?: Date
  expires_at?: Date
  updated_at?: Date
  deleted_at?: Date
  name?: string
  is_active?: boolean
  finished_at?: Date
  data?: any
  __meta__?: any
}
