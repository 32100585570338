export const STATUS_TYPE = {
  online: 'ONLINE',
  in_break: 'ON_BREAK',
  offline: 'OFFLINE',
  in_call: 'ON_CALL',
  off_call: 'OFF_CALL',
  off_break: 'OFF_BREAK',
  in_tabulating: 'ON_TABULATING',
  off_tabulating: 'OFF_TABULATING'
}
