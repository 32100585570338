import { Injectable } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { HttpClient } from '@angular/common/http'
import { BaseService } from 'src/app/services/base.service'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class QueueService extends BaseService {
  constructor (auth: AuthService, private http: HttpClient) {
    super(auth)
  }

  listQueueByPanelEnabled (organizationId: string): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/call-center/queue-list`, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  list (organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queues`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  store (organizationId: string, data): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  show (organizationId: string, queue: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  showPromise (organizationId: string, queue: string): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}`, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  update (organizationId: string, queue: string, data): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  delete (organizationId: string, queue: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}`, {
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  attach (organizationId: string, queue: string, data): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/attach`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  detach (organizationId: string, queue: string, data): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/detach`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  conversations (organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/call-center/queue-conversations`, {
      params,
      headers: {
        ...this.buildAuthToken(),
        'B1-Organization': organizationId
      }
    })
  }

  attachReason (organizationId: string, queue: string, data = {}): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/attach-reasons`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  detachReason (organizationId: string, queue: string, data = {}): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/detach-reasons`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  attachChannel (organizationId: string, queue: string, data = {}): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/attach-channels`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  detachChannel (organizationId: string, queue: string, data = {}): Promise<IApiResponse> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queues/${queue}/detach-channels`, data, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  myQueues (organizationId, params = {}): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/call-center/queues`, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        },
        params: {
          me: 'true',
          ...params
        }
      })
      .toPromise()
  }

  conversationByQueue (organizationId, id: string, params = {}): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/call-center/queue-conversations/queue`, {
        params: {
          queue_id: id,
          ...params
        },
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  conversationByOperator (organizationId, id: string, params = {}): Promise<any> {
    return this.http
      .get(`${environment.apiUrl}/call-center/queue-conversations/operator?user_id=${id}&only_today=false`, {
        params,
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  conversationPublic (organizationId, params = {}): Promise<any> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/call-center/queue-conversations`, {
        params,
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }

  getInQueue (organizationId, status: boolean): Promise<any> {
    return this.http
      .post<IApiResponse>(`${environment.apiUrl}/call-center/queue/availability?status=${status}`, null, {
        headers: {
          ...this.buildAuthToken(),
          'B1-Organization': organizationId
        }
      })
      .toPromise()
  }
}
