import { Injectable } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "src/app/services/base.service";
import { Observable } from "rxjs";
import { IApiResponse } from "src/app/interfaces/api-response";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OperatorsService extends BaseService {
  constructor(auth: AuthService, private http: HttpClient) {
    super(auth);
  }

  list(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  listV2(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators-v2`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  me(organizationId: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/me`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  mePromise(organizationId: string): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/call-center/operators/me`, {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      })
      .toPromise();
  }

  store(organizationId: string, data: any): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/operators`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  update(
    organizationId: string,
    operator: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.patch<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operator}`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  updateStatus(
    organizationId: string,
    operatorId: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .put<IApiResponse>(
        `${environment.apiUrl}/call-center/operators/${operatorId}/status`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  updateStatusObserver(organizationId: string, operatorId: string, data: any) {
    return this.http.put<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operatorId}/status`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  getAvailableStatus(organizationId: string, operatorId: string) {
    return this.http
      .get<IApiResponse>(
        `${environment.apiUrl}/call-center/operators/${operatorId}/available/status`,
        {
          params: {
            operatorId,
          },
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  getLastInBreakStatus(
    organizationId: string,
    id: string,
    type?: string
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${id}/status?type=${type}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  updatePromise(
    organizationId: string,
    operator: string,
    data: any
  ): Promise<IApiResponse> {
    return this.http
      .patch<IApiResponse>(
        `${environment.apiUrl}/call-center/operators/${operator}`,
        data,
        {
          headers: {
            ...this.buildAuthToken(),
            "B1-Organization": organizationId,
          },
        }
      )
      .toPromise();
  }

  show(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  showV2(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators-v2/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  delete(organizationId: string, id: string): Observable<IApiResponse> {
    return this.http.delete<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${id}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  listElegibleUsers(
    organizationId: string,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/elegible-users`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  listChannels(organizationId: string, params = {}): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/channels`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  listChannelsPromise(
    organizationId: string,
    params = {}
  ): Promise<IApiResponse> {
    return this.http
      .get<IApiResponse>(`${environment.apiUrl}/call-center/channels`, {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      })
      .toPromise();
  }

  listSkills(
    organizationId: string,
    operator: string,
    params = {}
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operator}/skills`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  storeSkill(
    organizationId: string,
    operator: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operator}/skills`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  stats(organizationId: string, date: string = null): Observable<IApiResponse> {
    const params: any = {};

    if (date) {
      params.date = date;
    }

    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/channels/stats`,
      {
        params,
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  listBreakReasons(organizationId: string): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/break-reasons`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  showOperatorByUser(
    organizationId: string,
    user: string
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/by-user/${user}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  storeBreak(
    organizationId: string,
    operator: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operator}/breaks`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  saveSchedule(
    organizationId: string,
    operator: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(
      `${environment.apiUrl}/call-center/operators/${operator}/schedule`,
      data,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }

  getAvailableOperatorInQueue(
    organizationId: string,
    queueId: string,
    reason: string,
    data: any
  ): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(
      `${environment.apiUrl}/call-center/conversations/available-operators?&queue_id=${queueId}&reason_slug=${reason}`,
      {
        headers: {
          ...this.buildAuthToken(),
          "B1-Organization": organizationId,
        },
      }
    );
  }
}
