import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { TranslateModule } from '@ngx-translate/core'
import { NavComponent } from './nav.component'
import { NavItemComponent } from '../nav-item/nav-item.component'
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [CommonModule, FontAwesomeModule, TranslateModule, RouterModule],
  declarations: [NavComponent, NavItemComponent],
  exports: [NavComponent]
})
export class NavModule {}
