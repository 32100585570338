import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PageLoaderComponent } from './components/page-loader/page-loader.component'
import { CustomizationTextComponent } from './components/customization-text/customization-text.component'
import { CustomizationSelectComponent } from './components/customization-select/customization-select.component'
import { CustomizationTextareaComponent } from './components/customization-textarea/customization-textarea.component'
import { CustomizationDateComponent } from './components/customization-date/customization-date.component'
import { CustomizationFormComponent } from './components/customization-form/customization-form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BDatePipe } from './pipes/date.pipe'
import { CallCenterChatMessagePreviewPipe } from './pipes/call-center-chat-message-preview.pipe'
import { MessageHistoryComponent } from './components/message-history/message-history.component'
import { ReceptiveCallComponentAlert } from './components/receptive-call-alert/receptive-call-alert.component'
import { ContactAccountsComponent } from './components/contact-accounts/contact-accounts.component'
import { AvatarModule } from 'ngx-avatar'
import { TranslateModule } from '@ngx-translate/core'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { GenericFilterPanelComponent } from './components/generic-filter-panel/generic-filter-panel.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { BsDropdownModule, PopoverModule, TooltipModule } from 'ngx-bootstrap'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { NgxSelectModule } from 'ngx-select-ex'

@NgModule({
  declarations: [
    PageLoaderComponent,
    CustomizationTextComponent,
    CustomizationSelectComponent,
    CustomizationTextareaComponent,
    CustomizationDateComponent,
    CustomizationFormComponent,
    BDatePipe,
    CallCenterChatMessagePreviewPipe,
    MessageHistoryComponent,
    ReceptiveCallComponentAlert,
    ContactAccountsComponent,
    GenericFilterPanelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AvatarModule,
    TranslateModule,
    NgbTooltipModule,
    FontAwesomeModule,
    BsDropdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    PopoverModule.forRoot(),
    NgxSelectModule,
    TooltipModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [
    PageLoaderComponent,
    CustomizationFormComponent,
    BDatePipe,
    CallCenterChatMessagePreviewPipe,
    MessageHistoryComponent,
    ReceptiveCallComponentAlert,
    ContactAccountsComponent,
    GenericFilterPanelComponent
  ]
})
export class SharedModule {}
