import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { IApiResponse } from 'src/app/interfaces/api-response'
import { environment } from 'src/environments/environment'
import { BaseService } from 'src/app/services/base.service'
import { AuthService as Auth } from 'src/app/services/auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  constructor (private http: HttpClient, auth: Auth) {
    super(auth)
  }

  login (data: object): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/auth/login`, data)
  }

  reset (token: string, password: string): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/auth/reset-password`, { password, password_confirmation: password }, { params: { token } })
  }

  recover (data: object): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/auth/forgot-password`, data)
  }

  signup (data: object): Observable<IApiResponse> {
    return this.http.post<IApiResponse>(`${environment.apiUrl}/auth/register`, data)
  }

  me (): Observable<IApiResponse> {
    return this.http.get<IApiResponse>(`${environment.apiUrl}/me/`, {
      headers: {
        ...this.buildAuthToken()
      }
    })
  }

  getToken (): Promise<{ token: string }> {
    const token = this._auth.getToken()
    const organization = this._auth.getOrganization()

    return this.http.post<{ token: string }>(`${environment.apiUrlv2}/auth/legacy`, {
      organizationId: organization?.id,
      token: token?.refreshToken,
      headers: {
        ...this.buildAuthToken()
      }
    }).toPromise()
  }
}
