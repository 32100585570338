import { Component, EventEmitter, Input, Output } from '@angular/core'
import { INotifications } from 'src/app/services/notification.service'
import * as moment from 'moment'
import 'moment/locale/pt-br'

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent {
  @Input() messagesNotificated: INotifications[]
  @Input() isLoading: boolean
  @Input() page: number
  @Input() canShowLoadMoreNotifications: boolean
  @Input() totalNotifications: number
  @Output() pageChange: EventEmitter<number>

  constructor () {
    this.pageChange = new EventEmitter()
  }

  getCalendarDate (date: string): string {
    moment.locale('pt-br')
    return moment(date).calendar()
  }

  handleLoadMore (): void {
    this.page += 1
    this.pageChange.emit(this.page)
  }
}
