import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  languageKey: string
  defaultLanguage: string

  constructor (private auth: AuthService) {
    this.defaultLanguage = 'pt'
    this.languageKey = '@b1/lang'
  }

  setLanguage (language: string): void {
    localStorage.setItem(this.languageKey, language)
  }

  getLanguage (): string {
    const language = localStorage.getItem(this.languageKey) || this.defaultLanguage
    return language
  }

  getTimezone (): string {
    return this.auth.getTimezone()
  }
}
